<!--
 * @Descripttion:
 * @Author: lina
 * @Date: 2023-01-13 14:14:20
 * @LastEditors: lina
 * @LastEditTime: 2022-05-25 18:49:56
-->
<template>
  <div class="native-bar">
    <div class="sep-container1">
      <!-- <div class="contain">
        <div class="content"> -->
          <!-- <div class="image" v-for="(item, index) in cutList" :key="index"> -->
          <el-carousel height="20.25rem">
            <el-carousel-item v-for="(item, index) in cutList" :key="index">
              <img :src="item.annexToken" class="image" style="width: 100%; height: 20.25rem;"/>
            </el-carousel-item>
          </el-carousel>
          <!-- </div> -->
        </div>
      </div>
    <!-- </div>
  </div> -->
</template>
<script>
import {
  getAdvertisement,
  downloadFile,
  downloadImage
} from '../../api/getFile.js'
// import cut1 from '../../assets/images/bottom/cut.png'
export default {
  name: 'MenuList',
  data () {
    return {
      activeIndex: '/homeMenu',
      cutList: []
    }
  },
  mounted () {
    this.getAdvertisement()
  },
  methods: {
    getAdvertisement () {
      const param = {}
      param.pageNo = 1
      param.pageSize = 20
      getAdvertisement(param).then((res) => {
        res.data.data.list.map((item) => {
          downloadFile({ g9s: [item.annexToken] }).then((res) => {
            // console.log(res.data.data, 'res11111')
            this.file = res.data.data.map((fileItem) => {
              // downloadImage(fileItem.fileToken).then((part) => {
              //   const blob = new Blob([part.data], {
              //     type: `image/${fileItem.extName}`
              //   })
              //   const urlList = URL.createObjectURL(blob)
              //   item.annexToken = urlList
              //   console.log(urlList, 'urlList')
              // })
              const blob = `/api/sys-storage/download_image?f8s=${fileItem.fileToken}`
              // const urlList = URL.createObjectURL(blob)
              // console.log(blob, 'blob')
              item.annexToken = blob

              return fileItem
            })
          })
          return item
        })
        this.cutList = res.data.data.list
        console.log(this.cutList)
        // console.log(res.data.data.list, '广告位')
      })
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    goto (url) {
      console.log(1)
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
.native-bar {
  width: 100%;
  .sep-container1 {
    width: 100%;
    // display: flex;
    // justify-content: center;
    // height: 319px;
    img {
      width: 100%;
      height: 100%;
    }

    .contain {
      width: 120rem;
      display: flex;
      background-repeat: no-repeat;
      background-size: cover;
      .content {
        width: 1920px;
        height: 100%;
        img {
          width: 1920px;
          height: 324px;
        }
      }
    }
  }
}
</style>
>
