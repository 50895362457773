import Vue from 'vue'
import Vuex from 'vuex'
import storage from '../utils/hooks/storage'
import { getToken } from '../api/user'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    usersArray: [],
    loginUserInfo: {
      id: '',
      userName: '',
      userFullname: '',
      email: '',
      phone: '',
      sex: '',
      clientId: '',
      tenantId: 0,
      avatarToken: '',
      userType: 0,
      isAdmin: false,
      isSuperAdmin: false,
      isSmallScreenUser: false,
      isBigScreenUser: false
    },
    token: ''
  },
  mutations: {
    updateUserInfo (state, payload) {
      state.loginUserInfo = {
        id: payload.id,
        userName: payload.userName,
        userFullname: payload.userFullname,
        userId: payload.userId,
        email: payload.email,
        phone: payload.phone,
        sex: payload.sex,
        clientId: payload.clientId,
        tenantId: payload.tenantId,
        avatarToken: payload.avatarToken,
        userType: payload.userType,
        isAdmin: payload.isAdmin,
        isSuperAdmin: payload.isSuperAdmin,
        isBigScreenUser: payload.isBigScreenUser,
        isSmallScreenUser: payload.isSmallScreenUser,
        departmentId: payload.departmentId
      }
    },
    updateToken (state, payload) {
      state.token = payload.access_token
    }
  },
  actions: {
    async getUserInfo (content, payload) {
      const res = await getToken(payload)
      if (res.data.access_token) {
        storage.set('username', res.data.userName)
        storage.set('user', JSON.stringify(res.data))
        storage.set('access_token', res.data.access_token)
        storage.set('is_oauth_login', false)
      }
      content.commit('updateUserInfo', res.data)
      content.commit('updateToken', res.data)
    },
    logout (content) {
      content.commit('updateUserInfo', {})
      content.commit('updateToken', {})
      storage.remove('username')
      storage.remove('user')
      storage.remove('access_token')
      storage.remove('is_oauth_login')
    }
  },
  modules: {}
})

export default store
