/*
 * @Author: zengxing
 * @Date: 2022-05-25 14:14:20
 * @LastEditors: zengxing
 * @LastEditTime: 2022-05-25 16:30:55
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Alert, BreadcrumbItem,Autocomplete, InputNumber, Breadcrumb, Popover, CollapseTransition, Transition, DatePicker, Avatar, Upload, Divider, Pagination, Row, Card, Carousel, CarouselItem, Menu, MenuItem, Checkbox, Submenu, Image, Form, FormItem, Input, Select, Option, Col, RadioGroup, Radio, Button, Message, Tabs, TabPane, Dialog } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css'
import { VueMasonryPlugin } from 'vue-masonry'

// import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
// import FawkesLib from 'fawkes-lib' // 组件库
// import 'fawkes-lib/lib/theme-chalk/index.css' // 组件库css
import axios from 'axios'
// Vue.use(FawkesLib, {
//   preciseSearch: true,
//   hideSwitch: false
//   // prefix // 项目前缀
// })
// import Router from 'vue-router'

// // 保存原来的push函数
// const routerPush = Router.prototype.push
// // 重写push函数
// Router.prototype.push = function push (location) {
//   // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
//   // 用来触发watch
//   if (typeof (location) === 'string') {
//     let Separator = '&'
//     if (location.indexOf('?') === -1) { Separator = '?' }
//     location = location + Separator + 'random=' + Math.random()
//   }

//   // 这个语句用来解决报错
//   // 调用原来的push函数，并捕获异常
//   return routerPush.call(this, location).catch(error => error)
// }

Vue.config.productionTip = false
Vue.component(CollapseTransition.name, CollapseTransition)
// el-breadcrumb
// el-breadcrumb-item
Vue.use('el-collapse-transition', CollapseTransition)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Dialog)
Vue.component('el-breadcrumb', Breadcrumb)
Vue.component('el-breadcrumb-item', BreadcrumbItem)

Vue.component('Transition', Transition)
Vue.component('el-popover', Popover)
Vue.component('el-date-picker', DatePicker)
Vue.component('el-avatar', Avatar)
Vue.component('el-upload', Upload)
Vue.component('el-row', Row)
Vue.component('el-divider', Divider)
Vue.component('el-pagination', Pagination)
Vue.component('el-card', Card)
Vue.component('el-carousel', Carousel)
Vue.component('el-carousel-item', CarouselItem)
Vue.component('el-menu', Menu)
Vue.component('el-menu-item', MenuItem)
Vue.component('el-submenu', Submenu)
Vue.component('el-image', Image)
Vue.component('el-form', Form)
Vue.component('el-form-item', FormItem)
Vue.component('el-input', Input)
Vue.component('el-select', Select)
Vue.component('el-option', Option)
Vue.component('el-col', Col)
Vue.component('el-radio-group', RadioGroup)
Vue.component('el-radio', Radio)
Vue.component('el-button', Button)
Vue.component('el-input-number', InputNumber)
// Vue.component('el-date-picker', DataPicker)
Vue.component('el-checkbox', Checkbox)
Vue.component('el-alert', Alert)
Vue.component('el-autocomplete',Autocomplete)
// Vue.component('el-divider', Devider)
Vue.use(VueMasonryPlugin)
Vue.prototype.$message = Message

Vue.config.errorHandler = function (err, vm, info) {
  console.log(err)
}
Vue.prototype.$axios = axios

if (window.innerWidth < 760) {
  const url = 'https://zjwater.org/mobile/'
  window.addEventListener('load', function () {
    setTimeout(function () {
      window.location.href = url
    }, 1000)
  })
} else {
  window.onbeforeunload = function () {
    //设置默认sessionStorage值
    sessionStorage.removeItem('searchTitle')
  }
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
