<!--
 * @Descripttion:
 * @Author: zengxing
 * @Date: 2022-05-25 14:14:20
 * @LastEditors: zengxing
 * @LastEditTime: 2022-05-25 18:49:56
-->
<template>
  <div
    class="sep-header"
    :style="{
      backgroundColor:
        styleChange == true || topMenuStyle == true ? '#FFFFFF' : bgColor
    }"
  >
    <div class="contain">
      <div class="sep-container">
        <div class="img">
          <!-- 浙江省城市水业协会 -->
          <div
            @click="
              $router.push({
                name: 'homeMenu'
              })
            "
            :class="
              styleChange || topMenuStyle ? 'sep-logo-search ' : 'sep-logo'
            "
          ></div>
        </div>
      </div>
      <div class="menuContain">
        <MenuList
          :styleChange="styleChange || topMenuStyle"
          @changeStyle="changeStyle"
        ></MenuList>
      </div>
      <!-- 默认搜索状态 -->
      <div
        :class="topMenuStyle ? 'search-two' : 'search'"
        @click="defaultSearch"
        v-show="!styleChange"
      >
        <el-input
          placeholder="请输入关键词"
          suffix-icon="el-icon-search"
          v-model="input2"
          maxlength="236px"
        >
        </el-input>
      </div>
      <!-- 实际搜索状态 -->
      <div class="react-search" v-show="styleChange">
        <div class="content-box">
          <el-input
            placeholder="请输入关键词"
            v-model="searchValue"
            style="width: 100%"
            @keyup.enter.native="searchChange"
            ref="searchInput"
            @blur="blur"
            @focus="focus"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search"
              @mousedown="searchChange"
            ></i>
            <span
              slot="suffix"
              style="
                display: inline-block;
                background: #eeeeee;
                width: 1px;
                height: 20px;
                margin: 0 16px;
                transform: translateY(6px);
              "
            ></span>
            <i
              slot="suffix"
              class="el-input__icon el-icon-close"
              @mousedown="clear"
            ></i>
          </el-input>
        </div>
        <el-popover
          placement="bottom"
          width="32rem"
          ref="popover"
          trigger="focus"
          :visible-arrow="false"
          style="padding-top: 0"
          v-model="searchDialog"
          class="search-popover"
        >
          <div class="category-search">
            <div class="search-history" v-if="historyList.length > 0">
              <div class="search-title">
                <div class="title">历史搜索</div>
                <el-button
                  type="text"
                  @mousedown.native="delClick"
                  style="color: #2c3e5c"
                >
                  <i class="el-icon-delete"></i
                ></el-button>
              </div>
              <div class="serch-content">
                <div
                  class="text text-bg"
                  v-for="(item, index) in historyList"
                  :key="index"
                  @mousedown="historyClick(item)"
                  :style="{ color: flag === item ? '#2669FF' : '' }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="recomm-search">
              <div class="search-title">
                <div class="title">推荐搜索</div>
              </div>
              <div class="serch-content">
                <div
                  class="text text-recomm"
                  v-for="(item, index) in recommList"
                  :key="index"
                  @mousedown="historyClick(item)"
                  :style="{ color: flag === item ? '#2669FF' : '' }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      <div class="loginPage">
        <div
          :class="styleChange || topMenuStyle ? 'buttons-search' : 'buttons'"
        >
          <el-button type="text" @click="loginButton">
            {{ $store.state.token ? '退出' : '登录' }}
          </el-button>
          <!-- <el-button type="text">注册</el-button> -->
        </div>
      </div>
      <!-- 登录 -->
    </div>
    <!-- 登录dialog -->
    <el-dialog
      :visible.sync="refLoginShow"
      center
      append-to-body
      custom-class="my-dialog"
    >
      <div class="login_contain">
        <div class="logo">
          <img :src="loginPic" alt class="img" />
        </div>
        <div class="main">
          <el-form
            :model="ruleForm"
            ref="ruleForm2"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item prop="username" size="medium">
              <el-input
                type="text"
                v-model="ruleForm.username"
                maxlength="16"
                clearable
                placeholder="请输入您的账号"
                size="medium"
                :class="mistake ? 'color' : ''"
                @keyup.enter.native="onEnter"
                @change="onChange"
              >
                <template #prefix>
                  <img
                    class="icon"
                    src="../../assets/images/index/user.png"
                    alt
                  />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                :type="changeStatus ? 'text' : 'password'"
                v-model="ruleForm.password"
                maxlength="16"
                clearable
                placeholder="请输入密码"
                @keyup.enter.native="onEnter"
                size="large"
                :class="mistake ? 'color' : ''"
              >
                <template #prefix>
                  <img
                    class="icon"
                    src="../../assets/images/index/password.png"
                    alt
                  />
                </template>
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-view"
                  @click="changeStatu"
                ></i>
              </el-input>
              <div class="mistake" v-if="mistake">{{ mistake }}</div>
            </el-form-item>
            <el-form-item class="remember_pwd">
              <el-checkbox v-model="checked" size="small">记住密码</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button
                class="login"
                type="primary"
                @click="submitForm"
                size="large"
                :loading="loading"
                :disabled="!cpdCanLogin"
                >{{ status ? '登录成功' : '登录' }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-button type="primary">登录</el-button> -->
      </div>
      <!-- <div class="mistake">
            <span>账号密码有误，请重新输入</span>
      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import MenuList from '../components/MenuList.vue'
import loginPic from '../../assets/images/index/login_logo.png'
import { passwordEncrpt, passwordUnEncrpt } from '../../utils/hooks/encrypt'
export default {
  props: ['bgColor', 'topMenuStyle'],
  components: {
    MenuList
  },
  name: 'HomeMenu',
  data() {
    return {
      flag: '',
      input2: '',
      searchValue: '',
      status: false,
      searchDialog: false,
      ruleForm: {
        username: '',
        password: ''
      },
      refLoginShow: false,
      checked: false,
      loading: false,
      changeStatus: false,
      loginPic,
      mistake: '',
      styleChange: false, //控制样式改变
      historyList: [],
      recommList: [
        '志愿服务',
        '培训',
        '会议',
        '论坛',
        '研讨会',
        '团体标准',
        '人事',
        '会员'
      ]
    }
  },
  mounted() {
    const obeserver = new ResizeObserver(() => {
      const { offsetHeight, offsetLeft } =
        document.getElementsByClassName('buttons')[0]
      const node = document.getElementsByClassName('my-dialog')[0]
      node.style.top = `${offsetHeight - 130}px`
      node.style.left = `${offsetLeft - 290}px`
    })
    obeserver.observe(document.body)
    // search.observe(document.body)
    if (localStorage.getItem('username') && localStorage.getItem('password')) {
      this.ruleForm.username = localStorage.getItem('username')
      this.ruleForm.password = passwordUnEncrpt(
        localStorage.getItem('password')
      )
      this.checked = true
    }
    //如果本地存储的数据historyList有值，直接赋值给data中的historyList
    if (JSON.parse(localStorage.getItem('historyList'))) {
      this.historyList = JSON.parse(localStorage.getItem('historyList'))
    }
  },
  watch: {},
  computed: {
    cpdCanLogin() {
      return this.ruleForm.username && this.ruleForm.password
    }
    // states () {
    //   if (!localStorage.getItem('jsc_access_token')) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants
      cb(results)
    },
    createStateFilter(queryString) {
      return searchValue => {
        return (
          searchValue.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    loginButton() {
      this.mistake = ''
      this.status = false
      this.styleChange = false
      if (!localStorage.getItem('jsc_access_token')) {
        this.refLoginShow = true
        if (!localStorage.getItem('username')) {
          this.ruleForm.username = ''
          this.ruleForm.password = ''
        }
        // this.states = false
      } else {
        this.$store.dispatch('logout')
        this.refLoginShow = false
        // this.states = true
        this.$message({
          message: '退出成功',
          type: 'success'
        })
      }
    },
    async submitForm() {
      try {
        this.loading = true
        await this.$store.dispatch('getUserInfo', {
          username: this.ruleForm.username.trim(),
          password: passwordEncrpt(this.ruleForm.password.trim()),
          grant_type: 'password'
        })
        // console.log(res.data)
        this.mistake = ''
        // this.$message({
        //   message: '登录成功',
        //   type: 'success'
        // })
        this.status = true
        setTimeout(() => {
          this.refLoginShow = false
          // this.$router.push({ path: '/homeMenu' })
        }, 2000)
        if (this.checked) {
          localStorage.setItem('username', this.ruleForm.username)
          localStorage.setItem(
            'password',
            passwordEncrpt(this.ruleForm.password)
          )
        } else {
          localStorage.removeItem('username')
          localStorage.removeItem('password')
        }
      } catch (error) {
        console.log(error)
        console.log(this.$refs.ruleForm2.validate)
        // this.$refs.ruleForm2.validate((valid) => {
        //   if (valid) {
        //     return false
        //   } else {
        //     return false
        //   }
        // })
        this.mistake = error.response.data
        // this.$message({
        //   message: error.response.data,
        //   type: 'warning'
        // })
      } finally {
        this.loading = false
        // setTimeout(() => {
        //   this.refLoginShow = false
        // }, 2000)
      }
    },
    // 键盘弹起
    onEnter() {
      if (this.ruleForm.username && this.ruleForm.password) {
        this.submitForm()
      } else {
        this.$message({
          message: '用户名跟密码需填写完整',
          type: 'warning'
        })
      }
    },
    onChange() {
      this.mistake = ''
    },
    changeStatu() {
      this.changeStatus = !this.changeStatus
    },
    defaultSearch() {
      this.styleChange = true
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
        this.searchDialog = true
      })
    },
    changeStyle() {
      this.styleChange = false
    },
    focus() {
      this.searchDialog = true
    },
    blur() {
      // this.searchDialog = false
      this.styleChange = false
      // this.changeStyle()
    },
    clear() {
      this.$nextTick(() => {
        this.searchValue = ''
        // this.styleChange = false
      })
    },
    historyClick(item) {
      //console.log(item, '111')
      this.flag = item
      this.goPage(item)
    },
    delClick(event) {
      event.preventDefault()
      localStorage.removeItem('historyList')
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      }, 100)
      this.styleChange = true
      this.historyList = []
    },
    searchChange(value) {
      // console.log(this.searchValue, ' this.searchValue')
      this.$nextTick(() => {
        if (this.searchValue) {
          let str = this.searchValue.replace(/(^\s*)|(\s*$)/g, '')
          this.goPage(str)
          this.saveStorage(str)
        }
      })
    },
    //跳转
    goPage(val) {
      if (this.$route.path != '/searchPage') {
        this.$router.push({
          name: 'searchPage',
          params: { keyValue: val, date: new Date().getTime() }
        })
      } else {
        this.$emit('getKeyWord', val)
      }
    },

    //缓存历史搜索
    saveStorage(value) {
      if (!this.historyList.includes(value) && this.historyList.length < 5) {
        this.historyList.unshift(value)
        localStorage.setItem('historyList', JSON.stringify(this.historyList))
      } else {
        //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
        let i = this.historyList.indexOf(value)
        this.historyList.splice(i, 1)
        if (this.historyList.length > 5) {
          this.historyList.pop()
        }
        this.historyList.unshift(value)
        localStorage.setItem('historyList', JSON.stringify(this.historyList))
      }
    }
  }
}
</script>
<style lang="less">
.v-modal {
  background: rgba(0, 0, 0, 0.001) !important;
}
.my-dialog {
  position: absolute !important;
  top: 72px;
  right: 8px;
  width: 368px !important;
  height: 307px;
  background: #ffffff;
  box-shadow: 0px 8px 12px 0px rgba(44, 62, 92, 0.08);
  border-radius: 4px;
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__header {
    display: none;
  }
  .login_contain {
    width: 368px;
    height: 307px;
    background: #ffffff;
    box-shadow: 0px 8px 12px 0px rgba(44, 62, 92, 0.08);
    border-radius: 4px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 64px;
    box-sizing: border-box;

    .logo {
      width: 183px;
      height: 28px;

      // background: url(./../assets/images/index/login_logo.png) no-repeat cover;
      .img {
        width: 100%;
        height: 100%;
      }
    }

    .main {
      margin-top: 22px;

      .el-form {
        width: 240px;

        .el-form-item {
          margin-bottom: 0;

          .el-form-item__content {
            margin-left: 0 !important;

            .el-input {
              width: 240px;
              height: 36px;
              margin-bottom: 16px;
              display: flex;
              align-items: center;
              box-sizing: border-box;

              .icon {
                width: 16px;
                height: 16px;
                margin-left: 10px;
                transform: translateY(2px);
              }

              .el-input__inner {
                font-size: 14px;
                height: 36px;
                font-family: MicrosoftYaHei;
                color: #333333;
                // margin-left: 15px;
                line-height: 16px;
                padding-left: 40px !important;
                padding-right: 0 !important;
                // border: 1px solid #333 ;

                &:focus {
                  border-color: rgba(50, 114, 255, 0.8);
                }
              }
            }
          }
        }
        .mistake {
          margin-top: -20px;
          font-size: 12px;
          height: 30px;
          color: #ff4d4f;
        }

        .remember_pwd {
          margin-bottom: 18px;

          .el-checkbox__inner {
            width: 16px !important;
            height: 16px !important;
            box-sizing: border-box;

            //  background: rgba(255,255,255,0);
            //  border-radius: 2px;
            //  border: 1px solid #DDDDDD;
            &.is-checked {
              background-color: #999 !important;
              border-color: #999;
            }
          }

          .el-checkbox__label {
            height: 19px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #999999;
            line-height: 19px;
          }
        }

        .login {
          width: 240px;
          height: 36px;
          background: #2669ff;
          border-radius: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          text-align: center;
          line-height: 10px;
        }
      }
      .color {
        // border: #2669ff 1px solid !important;
        .el-input__inner {
          font-size: 14px;
          height: 36px;
          font-family: MicrosoftYaHei;
          color: #333333;
          // margin-left: 15px;
          line-height: 16px;
          padding-left: 40px !important;
          padding-right: 0 !important;
          border: #ff4d4f 1px solid;

          // &:focus {
          //   border-color: rgba(50, 114, 255, 0.8);
          // }
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.sep-header {
  z-index: 999;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  position: fixed;
  // margin:0 auto;
  left: 0;
  top: 8;
  backdrop-filter: blur(0px);

  // background-color: var(--background);
  .contain {
    width: 120rem;
    position: relative;
    // width: 1920px;
    display: flex;
  }
  .sep-container {
    // width: 241px;
    width: 25rem;
    // margin-left: 34px;

    // margin-right: 135px;
    .img {
      height: 64px;
      display: flex;
      align-items: center;
      .sep-logo {
        // flex: 0 0 50%;
        height: 36px;
        line-height: 36px;
        // padding: 10px;
        display: block;
        width: 241px !important;
        // width: 15.0625rem;
        // height: 36px;
        margin-left: 2.125rem;
        margin-right: 7.4375rem;
        background: url(../../assets/images/logo@2x.png) center no-repeat;
        background-size: 241px 36px;
        // background: url(.../../assets) center no-repeat;
        filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../assets/images/logo.png', sizingMethod='scale');
        text-indent: -1000px;
        // overflow: hidden;
      }
      .sep-logo-search {
        // flex: 0 0 50%;
        height: 36px;
        line-height: 36px;
        // padding: 10px;
        display: block;
        width: 241px !important;
        // width: 15.0625rem;
        // height: 36px;
        margin-left: 2.125rem;
        margin-right: 7.4375rem;
        background: url(../../assets/images/logo-search.png) center no-repeat;
        background-size: 241px 36px;
        // background: url(.../../assets) center no-repeat;
        filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../assets/images/logo.png', sizingMethod='scale');
        text-indent: -1000px;
        // overflow: hidden;
      }
    }
  }

  .menuContain {
    width: 100%;
  }
  .search {
    width: 100%;
    // width:400px;
    display: flex;
    height: 64px;
    line-height: 64px;
    // margin-left: 340px;
    margin-left: 31rem;
    cursor: pointer;
    // width:236px;
    // height:36px;
    /deep/.el-input__inner {
      // width: 236px;
      width: 14.75rem;
      height: 36px;
      background: rgba(255, 255, 255, 0);
      border-radius: 2px;
      border: 1px solid rgba(255, 255, 255, 0.32);
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(255, 255, 255, 0.6);
    }

    /deep/.el-input__suffix {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .search-two {
    width: 100%;
    // width:400px;
    display: flex;
    height: 64px;
    line-height: 64px;
    // margin-left: 340px;
    margin-left: 31rem;
    cursor: pointer;
    // width:236px;
    // height:36px;
    /deep/.el-input__inner {
      // width: 236px;
      width: 14.75rem;
      height: 36px;
      background: rgba(255, 255, 255, 0);
      border-radius: 2px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(255, 255, 255, 0.6);
    }

    // /deep/.el-input__suffix {
    //   color: rgba(255, 255, 255, 0.8);
    // }
  }
  .react-search {
    width: 100%;
    margin-left: 14rem;
    .content-box {
      width: 32rem;
      padding: 14px 16px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(44, 62, 92, 0.12);
      box-sizing: border-box;
      /deep/.el-input__inner {
        height: 36px;
        background: rgba(255, 255, 255, 0);
        border-radius: 2px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        &:focus {
          border-color: #2669ff;
        }
      }
      /deep/.el-input__suffix {
        color: #2669ff;
      }
    }
    /deep/ .el-popover {
      width: 32rem;
      border-radius: 0;
      box-shadow: 0px 12px 12px 0px rgba(44, 62, 92, 0.08);
      min-height: 130px;
      padding: 0;
    }
    .search-popover {
      .category-search {
        padding: 20px 24px;
        .search-history {
          margin-bottom: 20px;
        }
        .search-title {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          .title {
            height: 21px;
            font-family: MicrosoftYaHei;
            font-weight: bold;
            font-size: 16px;
            color: #2c3e5c;
            line-height: 21px;
          }
          i {
            margin-left: 8px;
            font-size: 16px;
          }
        }
        .serch-content {
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          .text {
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #2c3e5c;
            line-height: 21px;
            text-align: justify;
            font-style: normal;
            cursor: pointer;
            margin-bottom: 12px;
            &:hover {
              color: #2669ff;
            }
          }
          .text-bg {
            padding: 8px 12px;
            background: rgba(58, 111, 254, 0.1);
            border-radius: 2px;
            margin-right: 16px;
          }
          .text-recomm {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .loginPage {
    width: 100%;
    // width:400px;
    display: flex;
    height: 64px;
    line-height: 64px;
    // margin-left: 340px;
    margin-left: 0.5rem;
    .buttons {
      display: flex;
      // width: 32px;
      // height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      // line-height: 21px;
      // margin-left: 395px;

      /deep/.el-button--text {
        width: 96px;
        height: 64;
        color: rgba(255, 255, 255, 0.6);

        &:hover {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 100%
          ) !important;
          opacity: 0.6;
          color: #ffffff !important;
        }
      }
    }
    .buttons-search {
      display: flex;
      // width: 32px;
      // height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      // line-height: 21px;
      // margin-left: 395px;

      /deep/.el-button--text {
        width: 96px;
        height: 64;
        color: #333333;

        &:hover {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 100%
          ) !important;
          opacity: 0.6;
          color: #333333 !important;
        }
      }
    }
  }

  .native-bar {
    width: 100%;

    .el-menu-item {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }

    //设置选中el-menu-item时的状态
    .el-menu-item .is-active {
      border-bottom-color: none !important;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      opacity: 0.3;
    }

    .el-menu {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}
</style>
<style>
</style>
