import request from '../utils/request'
// 会员名录
// /content/Roster/list
export function getRosterList(params) {
  return request({
    url: '/official-service/content/Roster/list',
    method: 'get',
    params
  })
}

// 广告位
// /content/ads/list
export function getAdvertisement(params) {
  return request({
    url: '/official-service/content/ads/list',
    method: 'get',
    params
  })
}
// 会员相册
export function getMemberAlbumPhoto(params) {
  return request({
    url: '/official-service/content/memberAlbum/list',
    method: 'get',
    params
  })
}
// 友情链接
export function getLinkPart(params) {
  return request({
    url: '/official-service/content/friendshipLinks/list',
    method: 'get',
    params
  })
}
export function getAboutList(params) {
  return request({
    url: '/official-service/about/professional/list',
    method: 'get',
    params
  })
}
export function getFile(params) {
  return request({
    url: '/official-service/indexPage/content',
    method: 'get',
    params
  })
}

export function getFile2(params) {
  return request({
    url: '/official-service/content/file/list',
    method: 'get',
    params
  })
}

// 获取文章内容
export function getFileContent(params) {
  return request({
    url: '/official-service/content/one',
    method: 'get',
    params
  })
}
export function getDocFile(params) {
  return request({
    url: '/official-service/content/doc/list',
    method: 'get',
    params
  })
}
export function getFileList(params) {
  return request({
    url: '/official-service/content/file/list',
    method: 'get',
    params
  })
}
// download
// download_image
export function downloadFile(data) {
  return request({
    url: '/sys-storage/file',
    method: 'post',
    data
  })
}

export function downloadImage(f8s) {
  return request({
    url: '/sys-storage/download_image',
    method: 'GET',
    params: {
      f8s
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 0,
    responseType: 'blob'
  })
}
export function getDownloadFile(f8s) {
  return request({
    url: '/sys-storage/download',
    method: 'GET',
    params: {
      f8s
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 0,
    responseType: 'blob'
  })
}
//文章搜索
export function getSearchContent(data) {
  return request({
    url: '/official-service/indexPage/search',
    method: 'post',
    data
  })
}
