import axios from 'axios'
import { NEED_SIGN_URL_LIST, TIME_ZONE } from './hooks/config'
import { sm4Encrypt } from './hooks/encrypt'
import storage from './hooks/storage'
import { getSign } from './hooks/sign'
import { Message } from 'element-ui'

//  可配置
const baseURL = '/api'
axios.defaults.timeout = window.CONFIG?.REQUEST_TIMEOUT || 60000 // 1分钟
// axios.defaults.retry = 3;
// axios.defaults.retryDelay = 10000;

// const CancelToken = axios.CancelToken;

// 创建实例
const instance = axios.create({
  baseURL,
  withCredentials: true
})
// 请求拦截
instance.interceptors.request.use(
  config => {
    const FawkesBiz = {
      timeZone: TIME_ZONE,
      timeFormat: 'yyyy-MM-dd HH:mm:ss'
    }

    if (!config.headers) {
      config.headers = {}
    }
    if (NEED_SIGN_URL_LIST.some((url) => config.url?.includes(url))) {
      config.params = getSign(config.params)
    } else {
      config.headers['Fawkes-Auth'] = storage.get('access_token') ?? ''
    }

    config.headers['Fawkes-Biz'] = sm4Encrypt(JSON.stringify(FawkesBiz))
    return config
  },
  error => {
    console.error('axios request error:==', error)
    Promise.reject(error)
  }
)

// 响应拦截
instance.interceptors.response.use((response) => {
  // console.log(response)
  return response
  // const { data, message, success } = response.data // 默认json格式
  // if (success) {
  //   console.log(data)
  //   return data
  // } else {
  //   Message({ type: 'error', message })
  //   // return Promise.reject(new Error(message))
  // }
}, async (error) => {
  // console.log(error)
  if (error.response.status === 401) {
    Message({ type: 'error', message: 'token超时了,请重新登录' })
    // 说明token超时了
    await this.$store.dispatch('logout') // 调用action 退出登录
  } else {
    // Message({ type: 'error', message: error.message })
  }
  return Promise.reject(error)
})

export default instance
