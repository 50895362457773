/*
 * @Descripttion:
 * @Author: zengxing
 * @Date: 2022-05-25 14:14:20
 * @LastEditors: zengxing
 * @LastEditTime: 2022-05-25 18:23:20
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeMenu from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/homeMenu'
  },
  {
    path: '/showFile',
    name: 'showFile',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/showFile.vue')
  },
  {
    path: '/showDetal',
    name: 'showDetal',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/showDetal.vue')
  },
  {
    path: '/showPicture',
    name: 'showPicture',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/showPicture.vue')
  },
  {
    path: '/searchPage',
    name: 'searchPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/searchPage.vue')
  },
  {
    path: '/homeMenu',
    name: 'homeMenu',
    component: HomeMenu
  },
  {
    path: '/aboutAssociation',
    name: 'AboutAssociation',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/aboutAssociation.vue')
  },
  {
    path: '/associationWork',
    name: 'AssociationWork',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/associationWork.vue')
  },
  {
    path: '/hottestRelease',
    name: 'HottestRelease',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/hottestRelease.vue')
  },
  {
    path: '/membershipService',
    name: 'MembershipService',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/membershipService.vue')
  },
  {
    path: '/markSpecification',
    name: 'MarkSpecification',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/markSpecification.vue')
  },
  {
    path: '/zheSays',
    name: 'ZheSays',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/zheSays.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router
