
/**
 * 应用公钥
 */
export const CLIENT = 'official-service'

/**
 * 应用私钥
 */
export const CLIENT_SECRET = 'official-service'
/**
 *  签名有效期，最短为180
 */
export const TTL = 180

export const STORAGE_PREFIX = 'jsc_'

export const STORAGE_ISOLATION = false

export const NEED_SIGN_URL_LIST = [
  '/sys-auth/oauth/token',
  '/sys-system/lang/detail/name',
  '/sys-system/clientInfo',
  '/sys-user/oauth/user/bind',
  '/sys-system/clients/cache/sync',
  '/sys-user/users/cache/sync',
  '/sys-auth/oauth/sms_captcha',
  '/sys-user/user/sms_register_captcha',
  '/sys-user/user/register',
  '/sys-auth/oauth/user/lock/num',
  '/sys-auth/oauth/img_captcha',
  '/sys-user/user/sms_captcha',
  '/sys-user/user/retrieve/pwd',
  '/sys-sso/oauth2/login',
  '/sys-system/dictionary/detail/list',
  '/sys-auth/oauth/behaviour_captcha',
  '/sys-auth/oauth/check_behaviour_captcha'
]

export const TIME_ZONE = '(UTC-08:00) 太平洋时间(美国和加拿大)'

export const DEFULT_FAVICON_URL = '/imgs/favicon.png'

export const RESOURCE_URL_PREFIX = 'http://192.168.71.121:9000/smart-screen/'
