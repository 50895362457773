<!--
 * @Descripttion:
 * @Author: zengxing
 * @Date: 2022-05-25 14:14:20
 * @LastEditors: zengxing
 * @LastEditTime: 2022-05-25 14:35:52
-->
<template>
  <div id="app">
    <router-view :key="key"></router-view>
  </div>
</template>
<script>
export default {
  computed: {
    key () {
      return this.$route.fullPath
    }
  },
  methods: {

  },
  mounted () {

  }
}

</script>
<style>
body{
  margin: 0;
  padding: 0;
}
#app {
  margin: 0;
  padding: 0;
}
</style>
<style lang="less" scoped>
/deep/.el-card {
  cursor: pointer;
}
/deep/.el-breadcrumb {
  height: 64px;
  font-size: 16px;
  display: flex;
  align-items: center;
  .el-breadcrumb__item {
    display: flex;
    align-items: center;
    .crumbsIcon {
      width: 22px !important;
      height: 22px !important;
    }
  }
}
</style>
