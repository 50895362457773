<template>
  <div>
    <div class="middleBox">
      <div class="menu-bar">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleChange"
          background-color="rgba(255, 255, 255, 0.1);"
          text-color="#555555"
          active-text-color="#3272FFFF"
        >
          <!-- <el-menu-item v-for="(item,index) in menuList" :key="index"></el-menu-item> -->
          <el-menu-item index="1">会员动态</el-menu-item>
          <el-menu-item index="2">通知公告</el-menu-item>
          <el-menu-item index="3">行业资讯</el-menu-item>
        </el-menu>
      </div>
      <div class="middle-page" v-if="keyValue == 1">
        <div>
          <el-row
            :gutter="24"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row; margin: 0"
          >
            <el-col style="width: 480px; padding: 0">
              <el-carousel height="320px">
                <el-carousel-item v-for="item in urlList" :key="item">
                  <img :src="urlList.length>0 ? item : url" class="image" />
                </el-carousel-item>
              </el-carousel>
            </el-col>
            <el-col style="width: 640px; padding: 0">
              <div class="notice">
                <div class="text">
                  <div
                    class="name"
                    @click="
                      $router.push({
                        name: 'showFile',
                        query: { sourceId: firstId },
                      })
                    "
                  >{{ firstTitle }}</div>
                  <div class="content">{{ firstDescription }}</div>
                  <!-- <div class="image">
                    <img :src="url3" />
                  </div>-->
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bottom">
          <el-row
            :gutter="24"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row; margin: 0"
          >
            <el-col :span="12" v-for="(item, index) in restList" :key="index">
              <div class="contain">
                <div class="left">
                  <div class="date">
                    {{
                    getDate(item.publishDate)
                    }}
                  </div>
                  <!-- <div class="date">{{}}</div>
                  <div class="date">{{}}</div>-->
                  <div class="year">{{ item.publishDate.split("-")[0] }}</div>
                </div>
                <div class="right" :title="item.description">
                  <div
                    class="title"
                    @click="
                      $router.push({
                        name: 'showFile',
                        query: { sourceId: item.sourceId },
                      })
                    "
                  >{{ item.title }}</div>
                  <div class="text" :title="item.description">{{ item.description }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="middle-page" v-if="keyValue == 2">
        <div>
          <el-row
            :gutter="24"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row; margin: 0"
          >
            <el-col style="width: 480px; padding: 0">
              <img :src="info" class="image" />
              <!-- <el-carousel height="320px">
                <el-carousel-item v-for="item in urlList" :key="item">
                  <img :src="info" class="image" />
                </el-carousel-item>
              </el-carousel>-->
            </el-col>
            <el-col style="width: 640px; padding: 0">
              <div class="notice">
                <div class="text">
                  <div
                    class="newname"
                    @click="
                      $router.push({
                        name: 'showDetal',
                        query: { sourceId: firstId },
                      })"
                  >{{ firstTitle }}</div>
                  <div class="content">{{ firstDescription }}</div>
                  <!-- <div class="image">
                    <img :src="url3" />
                  </div>-->
                </div>
                <!-- <div
                  class="imageContain"
                  @click="downloadBackup(firstAnnexToken)"
                >
                 <div class="image2" />
                </div>-->
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bottom1">
          <!-- <el-row
            :gutter="24"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row; margin: 0"
          >-->
          <!-- <el-col :span="12" v-for="(item, index) in restList" :key="index"> -->
          <div class="contain3" v-for="(item, index) in restList" :key="index">
            <div class="left">
              <div class="date">
                {{
                getDate(item.publishDate)
                }}
              </div>
              <div class="year">{{ item.publishDate.split("-")[0] }}</div>
            </div>
            <div class="center">
              <div
                class="title"
                @click="
                      $router.push({
                        name: 'showDetal',
                        query: { sourceId: item.sourceId },
                      })"
              >{{ item.title }}</div>
              <!-- <div class="text" :title="item.description">
                    {{ item.description }}
              </div>-->
            </div>
            <!-- <div
                  class="imageContain2"
                  @click="downloadBackup(item.annexToken)"
                   >
                    <div class="image2" />
            </div>-->
          </div>
          <!-- </el-col> -->
          <!-- </el-row> -->
        </div>
      </div>
      <div class="middle-page" v-if="keyValue == 3">
        <!-- <div v-if="list.length=0">
               暂无数据
        </div>-->
        <!-- <div > -->
        <div class="carouselList" style="flex-wrap: wrap; flex-direction: row; display: flex">
          <div style="width: 480px; padding: 0">
            <el-carousel height="320px">
              <el-carousel-item v-for="item in urlList" :key="item">
                <img :src="item" class="image" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div style="width: 640px; padding: 0">
            <div class="notice">
              <div class="text">
                <div
                  class="name"
                  @click="
                      $router.push({
                        name: 'showFile',
                        query: { sourceId: firstId },
                      })
                    "
                >{{ firstTitle }}</div>
                <div class="content">{{ firstDescription }}</div>
                <!-- <div
                  class="image"
                  @click="
                    $router.push({
                      name: 'showFile',
                      params: { sourceId: firstId },
                    })
                  "
                ></div>-->
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <div class="bottom">
          <el-row
            :gutter="24"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row; margin: 0"
          >
            <el-col :span="12" v-for="(item, index) in restList" :key="index">
              <div class="contain">
                <div class="left">
                  <div class="date">
                    {{
                    getDate(item.publishDate)
                    }}
                  </div>
                  <div class="year">
                    {{
                    item.publishDate !== null
                    ? item.publishDate.split("-")[0]
                    : "无"
                    }}
                  </div>
                </div>
                <div class="right">
                  <div
                    class="title"
                    @click="
                      $router.push({
                        name: 'showFile',
                        query: { sourceId: item.sourceId },
                      })
                    "
                  >{{ item.title ? item.title : "无" }}</div>
                  <div
                    class="text"
                    :title="item.description ? item.description : '无'"
                  >{{ item.description ? item.description : "无" }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="bottomButton">
      <div class="image" @click="goTo"></div>
    </div>
  </div>
</template>
<script>
// import url5 from '../../../assets/images/index/矩形2.png'
// import url4 from '../../../assets/images/index/矩形1.png'
import url2 from '../../../assets/images/index/矩形.png'
import url3 from '../../../assets/images/index/next.png'
import url4 from '../../../assets/images/index/new_next.png'
import info from '@/assets/images/index/info.png'
import url from '../../../assets/images/membershipService/back.png'
import {
  downloadFile,
  downloadImage,
  getDocFile
} from '../../../api/getFile.js'
export default {
  components: {},
  name: 'IndustryInfo',
  data () {
    return {
      // list1: [
      //   { name: '行业资讯', pageName: 'zheSays', idx: '2' },
      //   { name: '会员动态', pageName: 'membershipService', idx: '5' },
      //   { name: '通知公告', pageName: 'associationWork', idx: '2' }
      // ],
      previewBaseUrl: process.env.VUE_APP_PREVIEW_API,
      previewUrl: '',
      file: {},
      current_img_url: [],
      firstId: 1,
      firstTitle: '',
      firstDescription: '',
      firstAnnexToken: '',
      list: [],
      restList: [],
      param: {},
      changeValue: false,
      // urlList: [url2, url4, url5],
      urlList: null,
      activeIndex: '1',
      url2,
      url3,
      url4,
      keyValue: 1,
      logotype: '会员动态',
      bgColor: null,
      info,
      url
      // url: './../assets/images/banner.png'
    }
  },
  watch: {
  },
  created () {
    this.getFileList()
  },
  mounted () {
    this.getFileList()
  },
  methods: {
    getDate (time) {
      if (new Date(time).getMonth() + 1 < 10 && new Date(time).getDate() < 10) {
        return (
          '0' +
          (new Date(time).getMonth() + 1) +
          '-' +
          '0' +
          new Date(time).getDate()
        )
      } else if (
        new Date(time).getMonth() + 1 > 10 &&
        new Date(time).getDate() < 10
      ) {
        return (
          new Date(time).getMonth() + 1 + '-' + '0' + new Date(time).getDate()
        )
      } else if (
        new Date(time).getMonth() + 1 < 10 &&
        new Date(time).getDate() >= 10
      ) {
        return (
          '0' + (new Date(time).getMonth() + 1) + '-' + new Date(time).getDate()
        )
      } else {
        return new Date(time).getMonth() + 1 + '-' + new Date(time).getDate()
      }
    },
    goTo () {
      // console.log(this.keyValue)
      if (this.keyValue === '3') {
        this.$router.push({
          name: 'ZheSays',
          query: { index: '2' }
        })
      } else if (this.keyValue === '2') {
        this.$router.push({
          name: 'AssociationWork',
          query: { index: '2' }
        })
      } else {
        this.$router.push({
          name: 'MembershipService',
          query: { index: '5' }
        })
      }
    },
    changeClass () {
      this.changeValue = true
    },
    changeColor (name) {
      this.bgColor = name
    },
    handleChange (key, keyPath) {
      //  console.log('打印一下', key, keyPath)
      this.keyValue = key
      let logotype = ''
      if (this.keyValue === '1') {
        logotype = '会员动态'
      } else if (this.keyValue === '2') {
        logotype = '通知公告'
      } else if (this.keyValue === '3') {
        logotype = '行业资讯'
      }
      this.logotype = logotype
      this.getFileList()
    },
    getFileList () {
      const data = Object.assign({}, this.param)
      data.logotype = this.logotype
      data.pageNo = 1
      // eslint-disable-next-line
      data.pageSize = 5
      // eslint-disable-next-line
      // (this.logotype === '通知公告' ? getFileList : getDocFile) (data)
      getDocFile(data)
        .then(res => {
          console.log(res.data.data.list)
          if (res.status && res.data.data.list.length > 0) {
            this.list = res.data.data.list
            this.firstTitle = res.data.data.list[0].title
            this.firstDescription = this.list[0].description
            this.firstId = res.data.data.list[0].sourceId
            this.firstAnnexToken = res.data.data.list[0].annexToken
            this.restList = res.data.data.list.slice(1)
            downloadFile({
              g9s: [
                res.data.data.list[0][
                  this.logotype === '通知公告' ? 'thumbnail' : 'annexToken'
                ]
              ]
            }).then(res => {
              // console.log(res.data.data)
              const urlList = []
              this.file = res.data.data.forEach(item => {
                // console.log(item, 'item')
                // downloadImage(item.fileToken).then(part => {
                //   const blob = new Blob([part.data], {
                //     type: `image/${item.extName}`
                //   })
                // })
                const blob = `/api/sys-storage/download_image?f8s=${item.fileToken}`
                urlList.push(blob)
              })
              this.urlList = urlList
              // console.log(this.urlList, 'urlList')
            })
          } else {
            this.list = []
            this.firstTitle = '暂无数据'
            this.firstDescription = '暂无数据'
            this.restList = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    downloadBackup (row) {
      // console.log(row)
      this.download(row)
    },
    // download (row) {
    //   downloadFile({ g9s: [row] }).then((res) => {
    //     // console.log(res, '下载文件')
    //     // const fileList = res.data.data
    //     this.file = res.data.data.map((item) => {
    //       // console.log(item, 'item下载文件')
    //       getDownloadFile(item.fileToken).then((part) => {
    //         // console.log(part, 'part')
    //         const blob = new Blob([part.data], {
    //           type: `application/${item.extName}`
    //         })
    //         const fileName = item.fileName
    //         const link = document.createElement('a')
    //         link.download = fileName
    //         link.style.display = 'none'
    //         link.href = URL.createObjectURL(blob)
    //         document.body.appendChild(link)
    //         link.click()
    //         URL.revokeObjectURL(link.href)
    //         document.body.removeChild(link)
    //       })
    //       return item
    //     })
    //     //  download(row.backupFilePath).then(res => {

    //     // })
    //   })
    // },

    goto (url) {
      //   console.log(1)
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less" scoped>
.middle-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #f5f8fd;
  .middleBox {
  }
  .bottomButton {
    width: 1120px;
    height: 96px;
    position: relative;
    .image {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 14px;
      // margin: 31px 0 0 543px;
      background-image: url(../../../assets/images/index/next.png);
      background-repeat: repeat;
    }
  }
  .bottomButton:hover .image {
    background-image: url(../../../assets/images/index/new_next.png);
  }

  .menu-bar {
    width: 1120px;
    height: 64px;
    padding-top: 64px;
    // margin-left: 399px;

    .el-menu-item {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      width: 100%;
      text-align: center;
      background-color: rgba(255, 255, 255, 0) !important;
    }
    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }
    //设置选中el-menu-item时的状态
    .el-menu-item .is-active {
      // color:blue;
      border-bottom-color: none !important;
      // background: linear-gradient(
      //   180deg,
      //   rgba(255, 255, 255, 0) 0%,
      //   #ffffff 100%
      // );
      // opacity: 0.3;
    }
    .el-menu {
      display: flex;
      justify-content: space-between;
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .middle-page {
    width: 1120px;
    height: 100%;
    margin-top: 48px;
    // margin-left: 399px;
    .el-carousel__indicators--horizontal {
      bottom: 0;
      left: 75%;
    }
    // .el-carousel__indicator.is-active button
    .el-carousel__indicator.is-active button {
      background-color: #3272ff;
    }

    .notice {
      width: 640px;
      height: 258px;
      margin-top: 24px;
      background: linear-gradient(135deg, #ffffff 0%, #f0f4ff 100%);
      box-shadow: 0px 0px 16px 0px rgba(31, 49, 97, 0.08);
      border-radius: 0px 4px 4px 0px;
      position: relative;
      // position: relative;
      cursor: pointer;
      .noText {
        width: 24px;
        height: 4px;
        background: #3272ff;
        position: absolute;
        top: -227px;
        left: 241px;
      }
      .imageContain {
        top: 178px;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 32px;
        .image2 {
          width: 32px;
          height: 32px;
          margin-right: 32px;
          background: url('../../../assets/images/associationWork/download.png')
            no-repeat;
          &:hover {
            background: url('../../../assets/images/associationWork/download2.png')
              no-repeat;
          }
        }
      }
      .text {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #555555;
        line-height: 19px;
        padding: 48px 0 0 32px;
        margin-right: 32px;
        width: 576px;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        .name {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          line-height: 26px;
          overflow: hidden;
          // word-break: break-all;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .newname {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          line-height: 26px;
          overflow: hidden;
        }
        .content {
          font-size: 16px;
          width: 575px;
          font-family: MicrosoftYaHei;
          color: rgba(85, 85, 85, 0.8);
          line-height: 28px;
          margin-top: 24px;
          margin-right: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3 !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
        .image {
          position: absolute;
          bottom: 31px;
          right: 31px;
          width: 34px;
          height: 14px;
          // margin: 31px 0 0 543px;
          background-image: url(../../../assets/images/index/next.png);
        }
      }
    }
    .notice:hover .name {
      color: rgba(50, 114, 255, 1);
    }
    .notice:hover .newname {
      color: rgba(50, 114, 255, 1);
    }
    .notice:hover .image {
      background-image: url(../../../assets/images/index/new_next.png);
    }
    .time {
      font-size: 13px;
      color: #999;
    }

    // .bottom {
    //   margin-top: 13px;
    //   line-height: 12px;
    // }

    .button {
      padding: 0;
      float: right;
    }

    .image {
      width: 480px;
      height: 306px;
      display: block;
      // background: rgba(44, 62, 92, 0.05);
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: '';
    }

    .clearfix:after {
      clear: both;
    }
  }
  .bottom {
    margin-top: 64px;
    .contain {
      width: 33rem;
      height: 150px;
      display: flex;
      cursor: pointer;
      .left {
        width: 80px;
        height: 80px;
        background: #f8faff;
        border-radius: 2px;
        border: 1px solid #eeeeee;
        display: block;
        .date {
          margin: 12px 0 0 0;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #555555;
          text-align: center;
        }
        .year {
          margin: 8px 0 0 35px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #9d9d9d;
          line-height: 16px;
        }
      }
      .right {
        width: 416px;
        margin-left: 32px;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        .title {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          line-height: 26px;
          overflow: hidden;
          // word-break: break-all;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .text {
          margin-top: 16px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: rgba(85, 85, 85, 0.8);
          line-height: 28px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .imageContain2 {
          top: 44px;
          width: 32px;
          height: 32px;
          position: absolute;
          right: 32px;
          .image2 {
            width: 32px;
            height: 32px;
            margin-right: 32px;
            background: url('../../../assets/images/associationWork/download.png')
              no-repeat;
            &:hover {
              background: url('../../../assets/images/associationWork/download2.png')
                no-repeat;
            }
          }
        }
      }
    }
    .contain:nth-child(odd) {
      margin-right: 64px;
    }

    /* 偶数行的样式 */
    .contain:nth-child(even) {
      margin-right: 0;
    }
    .contain:hover {
      .left {
        width: 80px;
        height: 80px;
        background: #3272ff;
        border-radius: 2px;
        display: block;
        .date {
          margin: 12px 0 0 0;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
        }
        .year {
          margin: 8px 0 0 35px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
          line-height: 16px;
        }
      }
      .right {
        width: 416px;
        margin-left: 32px;
        //  display: -webkit-box;
        //   -webkit-box-orient: vertical;
        //   -webkit-line-clamp: 2;
        //   overflow: hidden;
        .title {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #3272ff;
          line-height: 26px;
          overflow: hidden;
          // word-break: break-all;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .text {
          margin-top: 16px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #555555;
          line-height: 28px;
        }
      }
    }
  }
  .bottom1 {
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // flex-direction: column;
    .contain3 {
      width: 528px;
      height: 96px;
      display: flex;
      cursor: pointer;
      align-items: center;
      background: #f8faff;
      border-radius: 2px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      margin-bottom: 36px;
      .left {
        width: 80px;
        height: 80px;
        margin-top: 15px;
        margin-left: 24px;
        // background: #f8faff;
        // border-radius: 2px;
        // border: 1px solid #eeeeee;
        display: block;
        .date {
          margin: 12px 0 0 0;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #555555;
          text-align: center;
        }
        .year {
          margin: 8px 0 0 35px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #9d9d9d;
          line-height: 16px;
        }
      }
      .center {
        width: 344px;
        margin: 0 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        .title {
          width: 344px;
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #2c3e5c;
          line-height: 26px;
          font-weight: bold;
        }
        // .text {
        //   margin-top: 16px;
        //   font-size: 16px;
        //   font-family: MicrosoftYaHei;
        //   color: rgba(85, 85, 85, 0.8);
        //   line-height: 28px;
        //   text-overflow: ellipsis;
        //   overflow: hidden;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 2;
        //   -webkit-box-orient: vertical;
        // }
      }
      .imageContain2 {
        width: 32px;
        height: 32px;
        margin-right: 24px;
        .image2 {
          width: 32px;
          height: 32px;
          background: url('../../../assets/images/associationWork/download.png')
            no-repeat;
          &:hover {
            background: url('../../../assets/images/associationWork/download2.png')
              no-repeat;
          }
        }
      }
    }
    .contain3:nth-child(odd) {
      margin-right: 64px !important;
    }
    .contain3:nth-last-of-type(-n + 2) {
      margin-bottom: 54px;
    }
    .contain3:hover {
      .left {
        width: 80px;
        height: 80px;
        color: #3272ff;
        margin-top: 15px;
        margin-left: 24px;
        display: block;
        .date {
          margin: 12px 0 0 0;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #3272ff;
          text-align: center;
        }
        .year {
          margin: 8px 0 0 35px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          // color: rgba(255, 255, 255, 0.7);
          color: #3272ff;
          line-height: 16px;
        }
      }
      .center {
        width: 344px;
        margin: 0 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        .title {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #3272ff;
          line-height: 26px;
        }
        // .text {
        //   margin-top: 16px;
        //   font-size: 16px;
        //   font-family: MicrosoftYaHei;
        //   color: #555555;
        //   line-height: 28px;
        // }
      }
    }
  }
  .el-menu-item {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  //设置选中el-menu-item时的状态
  .el-menu-item .is-active {
    border-bottom-color: none !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    opacity: 0.3;
  }
  .el-menu {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
