import { STORAGE_PREFIX, STORAGE_ISOLATION } from './config'

if (!STORAGE_ISOLATION) {
  sessionStorage.setItem('storageIsolation', '0')
}

// @ts-ignore
const NODE_ENV = process.env.NODE_ENV ?? 'development_'

export default {
  /**
   * @description: localStorage存储封装
   * @param {String} name
   * @param {String} value
   * @return: void
   */
  set: (name, value) => {
    const env = sessionStorage.getItem('storageIsolation')
      ? ''
      : `${NODE_ENV}_`
    localStorage.setItem(`${env}${STORAGE_PREFIX}${name}`, value)
  },

  /**
   * @description: localStorage获取封装
   * @param {String} name
   * @return: String
   */
  get: (name) => {
    const env = sessionStorage.getItem('storageIsolation')
      ? ''
      : `${NODE_ENV}_`
    return localStorage.getItem(`${env}${STORAGE_PREFIX}${name}`)
  },

  /**
   * @description: localStorage移除封装
   * @param {String} name
   * @return: void
   */
  remove: (name) => {
    const env = sessionStorage.getItem('storageIsolation')
      ? ''
      : `${NODE_ENV}_`
    localStorage.removeItem(`${env}${STORAGE_PREFIX}${name}`)
  },

  /**
   * @description: localStorage清空封装
   * @param {String} name
   * @return: String
   */
  clear: () => {
    const len = localStorage.length
    const keys = []

    for (let i = 0; i < len; i++) {
      const key = localStorage.key(i)
      if (key && key.startsWith(STORAGE_PREFIX)) {
        keys.push(key)
      }
    }
    keys.map((key) => localStorage.removeItem(key))
  }
}
