const secretKey = '43869399C1411A3BE71A99B35123AEFC'
const sm4 = new window.Sm4utils(secretKey)

export function sm4Encrypt (text) {
  return sm4.encryptData_ECB(text)
}

export function passwordEncrpt (text) {
  return sm4Encrypt(text)
}

export function passwordUnEncrpt (text) {
  return sm4.decryptData_ECB(text)
}
