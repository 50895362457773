<!--
 * @Descripttion:
 * @Author: lina
 * @Date: 2023-01-13 14:14:20
 * @LastEditors: lina
 * @LastEditTime: 2022-05-25 18:49:56
-->
<template>
  <div class="sep-banner" style="width: 100%; height: 100%">
    <el-carousel @change="changePhoto" :height="carouselHeight">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img
          :class="['img-' + index]"
          :src="item.photo"
          :style="{width:carouselWidth,height: carouselHeight}"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import banner1 from '../../assets/images/banners/1.png'
import banner2 from '../../assets/images/banners/2.png'
import banner3 from '../../assets/images/banners/3.png'
import banner4 from '../../assets/images/banners/4.png'
import banner5 from '../../assets/images/banners/5.png'
import banner6 from '../../assets/images/banners/6.png'
import banner7 from '../../assets/images/banners/7.png'

export default {
  name: 'MenuList',
  props: {
    carouselHeight: {
      type: String,
      default: '30rem'
    },
    // bannerList:[]
    bannerList: {
      type: Array,
      default: () => [
        // {
        //   photo: banner1
        // },
        // {
        //   photo: banner2
        // },
        {
          photo: banner3
        },
        {
          photo: banner4
        },
        // {
        //   photo: banner5
        // },
        {
          photo: banner6
        }
        // {
        //   photo: banner7
        // }
      ]
    }
  },
  data () {
    return {
      carouselWidth: '100%',
      screenHeight: (document.documentElement.clientHeight) / 2 + 'px',
      colorArr: []

    }
  },
  mounted () {
    this.changePhoto(0)
    window.onresize = () => {
      return () => {
        this.screenheight = (document.documentElement.clientHeight) / 2 + 'px'
      }
    }
  },
  //  props:['changeColor'],
  methods: {
    async changePhoto (index) {
      const colorThief = new window.ColorThief()
      const img = document.querySelector('.img-' + index)
      await new Promise((resolve) => {
        if (img.complete) {
          colorThief.getColor(img)
          const color = colorThief.getColor(img)
          color.push(0.8)
          this.colorArr = 'rgba(' + color.toString() + ')'
          resolve()
        } else {
          img.addEventListener('load', () => {
            const color = colorThief.getColor(img)
            color.push(0.8)
            this.colorArr = 'rgba(' + color.toString() + ')'
            this.$emit('changeColor', this.colorArr)
            resolve()
          })
        }
      })
      // 向父组件传递了两个值
      this.$emit('changeColor', this.colorArr)
      // console.log("颜色:", this.colorArr);
      // this.changeColor(this.colorArr);
    },
    goto (url) {
      // console.log(1)
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
@font-face {
  // eslint-disable-next-line no-mixed-spaces-and-tabs
  font-family: "YouSheBiaoTiHei";
  // eslint-disable-next-line no-mixed-spaces-and-tabs
  src: url(../../assets/font/优设标题黑.ttf);
}

.sep-banner {
  .el-carousel {
    width: 100%;
  }
  // height: 100%;
}
</style>
