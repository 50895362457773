<!--
 * @Descripttion:
 * @Author: zengxing
 * @Date: 2022-05-25 14:14:20
 * @LastEditors: zengxing
 * @LastEditTime: 2022-05-25 18:49:56
-->
<template>
  <div class="index">
    <topMenuList :bgColor="bgColor" ref="topMenuList"></topMenuList>
    <div class="bannerContain">
      <bannerList @changeColor="changeColor"></bannerList>
    </div>
    <div class="float_contain">
      <div class="contain showPopup">
        <!-- <el-popover placement="left" title='' width="204" trigger="hover" popper-class="area_popper"> -->
        <div class="popupPhoto">
          <img :src="cut" />
        </div>
        <div class="image">
          <img :src="item1" />
        </div>
        <div class="text">公众号</div>
        <!-- </el-popover> -->
        <el-divider></el-divider>
      </div>

      <div
        class="contain"
        @click="
          $router.push({
            name: 'MembershipService',
            query: { index: '3' }
          })
        "
      >
        <div class="image">
          <img :src="item2" />
        </div>
        <div class="text">一键入会</div>
        <el-divider></el-divider>
      </div>
      <div
        class="contain"
        @click="
          $router.push({
            name: 'AboutAssociation',
            query: { index: '7' }
          })
        "
      >
        <div class="image">
          <img :src="item3" />
        </div>
        <div class="text">联系我们</div>
        <el-divider></el-divider>
      </div>
      <div
        class="contain"
        @click=" $router.push({
            name: 'AboutAssociation',
            query: { index: '8' }
          })"
      >
        <div class="image">
          <img :src="item4" />
        </div>
        <div class="text">留言区</div>
      </div>
    </div>
    <div class="middle-contain">
      <industryPage></industryPage>
    </div>
    <dir class="middle-contain-two">
      <div class="containBox">
        <div class="topTitle">协会动态</div>
        <div class="wrap" id="wrap">
          <div class="pictures">
            <div class="pic pic1" @mouseover="show1 = true" @mouseleave="show1 = false">
              <!-- <transition name="el-zoom-in-bottom"> -->
              <div class="image1" v-show="!show1"></div>
              <!-- </transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="image2" v-show="show1"></div>
              </transition>
              <div class="text">
                <div class="name">现代化营业所创建经验交流会、主题联谊沙龙暨漏损管控蓝皮书编制推进会在绍兴柯桥圆满召开</div>
                <!-- <transition name="el-zoom-in-bottom">
                  <div class="content" v-show="show1">
                    中国城镇供水排水协会（简称“中国水协”)是全国性、行业性、非营利性的社会组织。成立于1985年，登记管理机关为民政部，党建领导机关为中央和国家机关工作委员会，业务主管部门为住房和城乡建设部。
                  </div>
                </transition>-->
              </div>
            </div>
            <div class="pic pic2" @mouseover="show2 = true" @mouseleave="show2 = false">
              <!-- <transition name="el-zoom-in-bottom"> -->
              <div class="image3" v-show="!show2"></div>
              <!-- </transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="image4" v-show="show2"></div>
              </transition>
              <div class="text">
                <div class="name">长三角地区供水行业供水管道工职业技能竞赛在浙江杭州圆满举办</div>
                <!-- <transition name="el-zoom-in-bottom">
                  <div class="content" v-show="show2">
                    杭州水务数智科技股份有限公司致力于成为全国一流的数字化水务和政府涉水行业领域综合解决方案提供商和服务商，以数智水务促进城市精细化管理，助推未来城市建设。
                  </div>
                </transition>-->
              </div>
            </div>
          </div>
          <div class="pictures">
            <div class="pic pic3" @mouseover="show3 = true" @mouseleave="show3 = false">
              <!-- <transition name="el-zoom-in-bottom"> -->
              <div class="image3" v-show="!show3"></div>
              <!-- </transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="image4" v-show="show3"></div>
              </transition>
              <div class="text">
                <div class="name">浙江智慧水务标准体系研讨会在绍兴柯桥圆满召开</div>
                <!-- <transition name="el-zoom-in-bottom">
                  <div class="content" v-show="show3">
                    杭州市水务集团有限公司是一家有93年发展历史，专业从事水务投资、建设、运营和资本运作的大型国有独资企业，始终秉持“水务姓党，水为民生”的宗旨，致力于推进城市水资源的合理利用与水环境的科学保护，在原水开发和利用、城市供排水一体化、污水污泥处置、环境提升养护等领域积累了丰富的实践经验，先后实现了供排水一体化和配供水一体化，构建起原水、供水、污水、水环境、建设开发、技术与服务六大板块，企业境内主体信用评级为AAA，境外主体信用评级为穆迪Baa1和惠誉BBB+。
                  </div>
                </transition>-->
              </div>
            </div>
            <div class="pic pic4" @mouseover="show4 = true" @mouseleave="show4 = false">
              <!-- <transition name="el-zoom-in-bottom"> -->
              <div class="image1" v-show="!show4"></div>
              <!-- </transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="image2" v-show="show4"></div>
              </transition>
              <div class="text">
                <div class="name">浙江水协乡镇委年会暨县乡镇供水农饮技术交流会和“两个规范化”宣贯会在温州苍南圆满召开</div>
                <!-- <transition name="el-zoom-in-bottom">
                  <div class="content" v-show="show4">
                    负责建筑、房地产行业安全生产监督管理，指导城市建设、管理与运行的安全生产管理工作。
                  </div>
                </transition>-->
              </div>
            </div>
          </div>
          <div class="pictures">
            <div class="pic pic5" @mouseover="show5 = true" @mouseleave="show5 = false">
              <!-- <transition name="el-zoom-in-bottom"
              >-->
              <div class="image1" v-show="!show5"></div>
              <!-- </transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="image2" v-show="show5"></div>
              </transition>
              <div class="text">
                <div class="name">2023（第六届）长三角三省一市城镇供水一体化合作论坛在宁波胜利召开</div>
                <!-- <transition name="el-zoom-in-bottom">
                  <div class="content" v-show="show5">
                    浙江省水利厅主要职责为负责保障水资源的合理开发利用。拟订水利战略规划和政策，起草有关地方性法规、规章草案。组织编制全省水资源战略规划、全省重要江河湖泊的流域（区域）综合规划、防洪规划等重大水利规划等。
                  </div>
                </transition>-->
              </div>
            </div>
            <div class="pic pic6" @mouseover="show6 = true" @mouseleave="show6 = false">
              <!-- <transition name="el-zoom-in-bottom"
              >-->
              <div class="image3" v-show="!show6"></div>
              <!-- </transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="image4" v-show="show6"></div>
              </transition>
              <div class="text">
                <div class="name">省水协供水委2023年年会暨漏损管控蓝皮书、新版现代化营业所评价标准发布宣贯和管表阀国际新技术交流论坛在绍兴圆满举办</div>
                <!-- <transition name="el-zoom-in-bottom">
                  <div class="content" v-show="show6">
                    制定全省大气、水、海洋、土壤、噪声、光、恶臭、固体废物、化学品、机动车等的污染防治管理制度并监督实施。
                    会同有关部门监督管理饮用水水源地生态环境保护工作，组织指导城乡生态环境综合整治工作，监督指导农业面源污染治理工作。
                  </div>
                </transition>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </dir>
    <div class="middle-contain-one">
      <associationJournal></associationJournal>
    </div>
    <div class="MemberServices">
      <div class="MemberContain">
        <div class="topTitle">会员服务</div>
        <div class="contain">
          <div
            class="notice"
            @click="
              $router.push({
                name: 'MembershipService',
                query: { index: '1' }
              })
            "
          >
            <div class="text">
              <div class="title">管理文件</div>
              <div class="name">合理制定多个管理文件</div>
              <div class="content">
                <span class="turnButton">前往管理文件</span>
                <i class="el-icon-right"></i>
              </div>
              <div class="image">
                <img :src="photo1" />
              </div>
            </div>
          </div>
          <div
            class="notice"
            @click="
              $router.push({
                name: 'MembershipService',
                query: { index: '2' }
              })
            "
          >
            <div class="text">
              <div class="title">会员名录</div>
              <div class="name">多家会员订阅服务</div>
              <div class="content">
                <span class="turnButton">前往会员名录</span>
                <i class="el-icon-right"></i>
              </div>
              <div class="image">
                <img :src="photo2" />
              </div>
            </div>
          </div>
          <div
            class="notice"
            @click="
              $router.push({
                name: 'MembershipService',
                query: { index: '3' }
              })
            "
          >
            <div class="text">
              <div class="title">一键入会</div>
              <div class="name">操作方便，助您快速入会</div>
              <div class="content">
                <span class="turnButton">前往一键入会</span>
                <i class="el-icon-right"></i>
              </div>
              <div class="image">
                <img :src="photo3" />
              </div>
            </div>
          </div>
          <div class="notice">
            <div class="text">
              <div class="title">服务项目</div>
              <div class="name">多样化支持，提供全流程服务</div>
              <div class="content">
                <span class="turnButton">前往服务项目</span>
                <i class="el-icon-right"></i>
              </div>
              <div class="image">
                <img :src="photo4" />
              </div>
            </div>
          </div>
          <!-- <img :src="item" class="image" /> -->
        </div>
      </div>
    </div>
    <div class="LinkPart">
      <div class="linkContain">
        <div class="topTitle">友情链接</div>
        <div class="contain">
          <div class="notice" v-for="(item, index) in linkList" :key="index" @click="linkTo(index)">
            <img :src="item.annexToken" />
          </div>
        </div>
      </div>
    </div>
    <bottomTwo></bottomTwo>
    <bottomOne></bottomOne>
  </div>
</template>

<script>
import cut from '../assets/images/index/popup.png'
import item1 from '../assets/images/index/wechat.svg'
import item2 from '../assets/images/index/finger.svg'
import item3 from '../assets/images/index/concat.svg'
import item4 from '../assets/images/index/infoList.svg'

import link1 from '../assets/images/index/link/link1.png'
import link2 from '../assets/images/index/link/link2.png'
import link3 from '../assets/images/index/link/link3.png'
import link4 from '../assets/images/index/link/link4.png'
import link5 from '../assets/images/index/link/link5.png'
import link6 from '../assets/images/index/link/link6.png'
import link7 from '../assets/images/index/link/link7.png'
import link8 from '../assets/images/index/link/link8.png'
import link9 from '../assets/images/index/link/link9.png'
import link10 from '../assets/images/index/link/link10.png'

import photo1 from '../assets/images/index/腾讯云.png'
import photo2 from '../assets/images/index/腾讯云2.png'
import photo3 from '../assets/images/index/腾讯云3.png'
import photo4 from '../assets/images/index/腾讯云4.png'

import pic1 from '../assets/images/index/pic1.png'
import pic2 from '../assets/images/index/pic2.png'
import pic3 from '../assets/images/index/pic3.png'
import pic4 from '../assets/images/index/pic4.png'
import pic5 from '../assets/images/index/pic5.png'
import pic6 from '../assets/images/index/pic6.png'

import url5 from '../assets/images/index/矩形2.png'
import url4 from '../assets/images/index/矩形1.png'
import url2 from '../assets/images/index/矩形.png'
import url3 from '../assets/images/index/next.png'

import cut1 from '../assets/images/aboutAssociation/3-1.png'

// import ImageContainer from '@/components/ImageContainer/index.vue'

// import HomeMenu from './components/homeMenu.vue'
// import MenuList from './components/MenuList.vue'
import industryPage from './components/indexPage/industryPage.vue'
import topMenuList from './components/topMenuList.vue'
import bottomTwo from './components/bottomTwo.vue'
import bottomOne from './components/bottomOne.vue'
import bannerList from './components/bannerList.vue'
import associationJournal from './components/associationJournal.vue'
import banner from './../assets/images/banner.png'
import banner1 from './../assets/images/banner1.jpg'
import banner2 from './../assets/images/banner2.jpg'

import sep2 from './../assets/images/22.png'
import sep5 from './../assets/images/33.png'
import sep1 from './../assets/images/l1.png'
import sep3 from './../assets/images/l2.png'
import sep4 from './../assets/images/l3.png'

// import cut1 from './../assets/images/index/cut1.png'
import cut2 from './../assets/images/index/cut2.png'
import cut3 from './../assets/images/index/cut3.png'
import cut4 from './../assets/images/index/cut4.png'
// import cut5 from './../assets/images/index/cut5.png'
import {
  getLinkPart,
  downloadFile,
  downloadImage,
  getMemberAlbumPhoto,
  getAdvertisement
} from '../api/getFile.js'

export default {
  components: {
    industryPage,
    topMenuList,
    // MenuList,
    bannerList,
    bottomTwo,
    bottomOne,
    associationJournal
    // ImageContainer,
    // HomeMenu
  },
  name: 'HomeMenu',
  data () {
    return {
      linkList: [],
      showPopup: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      textarea: '',
      currentPage4: 1,
      imgUrls: [pic1, pic2, pic3, pic4, pic5, pic6],
      cut,
      item1,
      item2,
      item3,
      item4,
      link1,
      link2,
      link3,
      link4,
      link5,
      link6,
      link7,
      link8,
      link9,
      link10,
      photo1,
      photo2,
      photo3,
      photo4,
      pic1,
      pic2,
      pic3,
      pic4,
      pic5,
      pic6,
      // loginPic,
      changeValue: false,
      urlList: [url2, url4, url5],
      activeIndex: '1',
      url2,
      url3,
      keyValue: 1,
      bgColor: null,
      url: './../assets/images/banner.png',
      bannerList: [banner1, banner, banner2],
      cutList: [cut1, cut2, cut3, cut4],
      sepList: [sep1, sep2, sep3, sep4, sep5]
    }
  },
  mounted () {
    this.getLinkPart()
    this.getMemberAlbumPhoto()
  },
  // computed: {
  //   cpdCanLogin () {
  //     return this.ruleForm.username && this.ruleForm.password
  //   }
  // },
  methods: {
    getAdvertisement () {
      const param = {}
      param.pageNo = 1
      param.pageSize = 20
      getAdvertisement(param).then(res => {
        // console.log(res.data.data.list, '广告位')
      })
    },
    getMemberAlbumPhoto () {
      const param = {}
      param.pageNo = 1
      param.pageSize = 20
      getMemberAlbumPhoto(param).then(res => {
        // console.log(res.data.data.list, '会员相册')
        // res.data.data.list.map(item => {
        //   downloadFile({ g9s: [item.annexToken] }).then((res) => {
        //     console.log(res, 'res会员相册')
        //     // const fileList = res.data.data
        //     this.file = res.data.data.map((fileItem) => {
        //       downloadImage(fileItem.fileToken).then(part => {
        //         // const urlList = null
        //         //       console.log(part, 'part')
        //         const blob = new Blob([part.data], {
        //           type: `image/${fileItem.extName}`
        //         })
        //         const urlList = URL.createObjectURL(blob)
        //         item.annexToken = urlList
        //         console.log(urlList, '会员相册urlList')
        //       })
        //       return fileItem
        //     })
        //   // this.urlList = urlList
        //   //  console.log(this.urlList, 'urlList')
        //   })
        //   return item
        // })
        // this.linkList = res.data.data.list
        // console.log(this.linkList, 'this.linkList')
      })
    },
    linkTo (index) {
      window.open(this.linkList[index].linkUrl, '_blank')
    },
    downloadFile (annexToken) {
      let urlList = null
      downloadFile({ g9s: [annexToken] }).then(res => {
        //   console.log(res, 'res11111')
        // const fileList = res.data.data

        this.file = res.data.data.map(item => {
          downloadImage(item.fileToken).then(part => {
            // console.log(part, 'part')
            const blob = new Blob([part.data], {
              type: `image/${item.extName}`
            })
            urlList = URL.createObjectURL(blob)

            // urlList.push(URL.createObjectURL(blob))
          })
          return item
        })
        // this.urlList = urlList
        //  console.log(this.urlList, 'urlList')
      })
      return urlList
    },
    getLinkPart () {
      const param = {}
      param.pageNo = 1
      param.pageSize = 20
      getLinkPart(param).then(res => {
        // console.log(res.data.data.list, '友情链接')
        res.data.data.list.map(item => {
          downloadFile({ g9s: [item.annexToken] }).then(res => {
            // console.log(res, 'res11111')
            // const fileList = res.data.data
            this.file = res.data.data.map(fileItem => {
              // downloadImage(fileItem.fileToken).then(part => {
              //   const blob = new Blob([part.data], {
              //     type: `image/${fileItem.extName}`
              //   })
              //   const urlList = URL.createObjectURL(blob)
              //   item.annexToken = urlList
              // })
              const blob = `/api/sys-storage/download_image?f8s=${fileItem.fileToken}`
              item.annexToken = blob
              return fileItem
            })
            // this.urlList = urlList
            //  console.log(this.urlList, 'urlList')
          })
          return item
        })
        this.linkList = res.data.data.list
        //  console.log(this.linkList, 'this.linkList')
      })
    },
    selectMenu (item) {
      // this.groupId = item.id
      const returnEle = document.querySelector('#Comments')
      returnEle.scrollIntoView(true)
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      // this.getDocFile()
    },
    // 当前页数改变
    handleCurrentChange (val) {
      this.pagination.pageNo = val
      // this.getDocFile()
    },
    changeClass () {
      this.changeValue = true
    },
    changeColor (name) {
      //  console.log(name, 'name')
      this.bgColor = name
    },
    handleSelect (key, keyPath) {
      this.keyValue = key
    },
    goto (url) {
      // console.log(1)
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
.index {
  width: 100%;
}

.menuContain {
  // width: 100%;
  display: flex;
  justify-content: center;
}

.bannerContain {
  width: 100%;
  height: 320px;
}

.sep-header {
  z-index: 999;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  position: fixed;
  // margin:0 auto;
  left: 0;
  top: 8;
  backdrop-filter: blur(0px);
  // background-color: var(--background);

  .sep-container {
    .sep-logo {
      flex: 0 0 50%;
      height: 64px;
      line-height: 64px;
      // padding: 10px;
      display: block;
      width: 241px;
      // height: 36px;
      padding-left: 34px;
      padding-right: 135px;
      background: url(./../assets/images/logo.png) center no-repeat;
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../assets/images/logo.png', sizingMethod='scale');
      text-indent: -1000px;
      // overflow: hidden;
    }
  }

  .loginPage {
    display: flex;
    height: 64px;
    line-height: 64px;
    padding-left: 340px;

    .search {
      // width:236px;
      // height:36px;
      /deep/.el-input__inner {
        width: 236px;
        height: 36px;
        background: rgba(255, 255, 255, 0);
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.32);
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 0.6);
      }

      /deep/.el-input__suffix {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .buttons {
      // width: 32px;
      // height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(255, 255, 255, 0.6);

      // line-height: 21px;
      // margin-left: 48px;
      /deep/.el-button--text {
        width: 96px;
        height: 64;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .native-bar {
    width: 100%;

    .el-menu-item {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }

    //设置选中el-menu-item时的状态
    .el-menu-item .is-active {
      border-bottom-color: none !important;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      opacity: 0.3;
    }

    .el-menu {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

// .sep-banner {
//   img {
//     width: 1920px;
//     height: 480px;
//   }
// }

.sep-container1 {
  width: 100%;
  height: 100%;
  background: url(./../assets/images/logo.png) center no-repeat;
  background-size: cover;

  img {
    width: 1920px;
    height: 100%;
  }
}

.float_contain {
  width: 80px;
  height: 351px;
  background: #ffffff;
  box-shadow: 0px 8px 12px 0px rgba(44, 62, 92, 0.08);
  border-radius: 4px;
  position: fixed;
  top: 406px;
  right: 32px;
  z-index: 1000;

  /deep/.el-divider--horizontal {
    display: block;
    height: 1px;
    width: 80px;
    margin: 0;
  }

  .popupPhoto {
    visibility: hidden;
    position: absolute;
    right: 104px;
    top: -3px;
    width: 204px;
    height: 204px;

    img {
      width: 204px;
      height: 204px;
    }
  }

  .contain:first-of-type:hover .popupPhoto {
    visibility: visible;
    position: absolute;
    right: 80px;
    top: -3px;
    width: 204px;
    height: 204px;

    img {
      width: 204px;
      height: 204px;
    }
  }

  .contain {
    width: 80px;
    height: 68px;
    // /deep/.el-popover.area_popper {
    //   min-width: 204px !important;
    //   padding: 0 !important;
    //   box-shadow: none !important;
    // }

    .image {
      margin: 16px 0 0 24px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .text {
      // width: 56px;
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(85, 85, 85, 1);
      // line-height: 19px;
      text-align: center;
      // margin-top: 4px;
      margin-bottom: 16px;
    }
  }

  .contain:hover .text {
    color: rgba(50, 114, 255, 1);
  }
}

.middle-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #f5f8fd;
  margin-top: 160px;

  .middleBox {
    width: 1120px;
  }

  .menu-bar {
    width: 1120px;
    height: 64px;
    padding-top: 64px;
    // margin-left: 399px;

    .el-menu-item {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      width: 100%;
      text-align: center;
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }

    //设置选中el-menu-item时的状态
    .el-menu-item .is-active {
      // color:blue;
      border-bottom-color: none !important;
      // background: linear-gradient(
      //   180deg,
      //   rgba(255, 255, 255, 0) 0%,
      //   #ffffff 100%
      // );
      // opacity: 0.3;
    }

    .el-menu {
      display: flex;
      justify-content: space-between;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .middle-page {
    width: 1120px;
    height: 100%;
    margin-top: 48px;

    // margin-left: 399px;
    .el-carousel__indicators--horizontal {
      bottom: 0;
      left: 75%;
    }

    // .el-carousel__indicator.is-active button
    .el-carousel__indicator.is-active button {
      background-color: #3272ff;
    }

    .notice {
      width: 640px;
      height: 258px;
      margin-top: 24px;
      background: linear-gradient(135deg, #ffffff 0%, #f0f4ff 100%);
      box-shadow: 0px 0px 16px 0px rgba(31, 49, 97, 0.08);
      border-radius: 0px 4px 4px 0px;

      // position: relative;
      .noText {
        width: 24px;
        height: 4px;
        background: #3272ff;
        position: absolute;
        top: -227px;
        left: 241px;
      }

      .text {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #555555;
        line-height: 19px;
        padding: 48px 0 0 32px;

        .name {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          line-height: 26px;
        }

        .content {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: rgba(85, 85, 85, 0.8);
          line-height: 28px;
          margin-top: 24px;
          margin-right: 32px;
        }

        .image {
          width: 34px;
          height: 14px;
          margin: 31px 0 0 543px;
        }
      }
    }

    .time {
      font-size: 13px;
      color: #999;
    }

    // .bottom {
    //   margin-top: 13px;
    //   line-height: 12px;
    // }

    .button {
      padding: 0;
      float: right;
    }

    .image {
      width: 480px;
      height: 320px;
      display: block;
      // background: rgba(44, 62, 92, 0.05);
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: '';
    }

    .clearfix:after {
      clear: both;
    }
  }

  .bottom {
    margin-top: 64px;

    .contain {
      width: 560px;
      height: 150px;
      display: flex;

      .left {
        width: 80px;
        height: 80px;
        background: #f8faff;
        border-radius: 2px;
        border: 1px solid #eeeeee;
        display: block;

        .date {
          margin: 12px 0 0 0;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #555555;
          text-align: center;
        }

        .year {
          margin: 8px 0 0 35px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #9d9d9d;
          line-height: 16px;
        }
      }

      .right {
        width: 416px;
        margin-left: 32px;

        .title {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          line-height: 26px;
        }

        .text {
          margin-top: 16px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: rgba(85, 85, 85, 0.8);
          line-height: 28px;
        }
      }
    }

    .contain:hover {
      .left {
        width: 80px;
        height: 80px;
        background: #3272ff;
        border-radius: 2px;
        display: block;

        .date {
          margin: 12px 0 0 0;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
        }

        .year {
          margin: 8px 0 0 35px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
          line-height: 16px;
        }
      }

      .right {
        width: 416px;
        margin-left: 32px;

        .title {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #3272ff;
          line-height: 26px;
        }

        .text {
          margin-top: 16px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #555555;
          line-height: 28px;
        }
      }
    }
  }

  .el-menu-item {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  //设置选中el-menu-item时的状态
  .el-menu-item .is-active {
    border-bottom-color: none !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    opacity: 0.3;
  }

  .el-menu {
    background-color: rgba(255, 255, 255, 0);
  }
}

.middle-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #f5f8fd;
  // margin-top: 160px;
}
.middle-contain-one {
  width: 100%;
  // height: 690px;
  background: #ffffff;
}
.middle-contain-two {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;

  .containBox {
    width: 1920px;
  }

  div.item {
    transition: all 0.5s;
    /*width : 200px;*/
    height: auto;
    padding: 5px;
    box-sizing: border-box;
  }

  .wrap {
    column-width: 1920px;
    column-count: auto;
    column-gap: 1rem;
  }

  .topTitle {
    // width: 1920px;
    height: 188px;
    font-size: 36px;
    font-family: YouSheBiaoTiHei;
    color: #2c3e5c;
    line-height: 188px;
    text-align: center;
    background-color: #fff;
  }

  .pictures {
    // width: 1920px;
    height: 320px;
    display: flex;
    margin: 0;

    .text {
      position: absolute;
      bottom: 48px;
      left: 64px;
      margin: 0 64px 0 0;

      .name {
        font-size: 32px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
      }

      .content {
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 0.8);
        margin-top: 24px;
        visibility: hidden;
      }
    }

    .pic:hover .content {
      font-size: 24px;
      font-family: MicrosoftYaHei;
      color: rgba(255, 255, 255, 0.8);
      margin-top: 24px;
      visibility: visible;
    }

    .pic {
      .image1 {
        width: 640px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.32);
      }

      .image2 {
        width: 640px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(58, 111, 254, 0.9);
      }

      .image3 {
        width: 1280px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.32);
      }

      .image4 {
        width: 1280px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(58, 111, 254, 0.9);
      }
    }

    .pic1 {
      width: 640px;
      height: 320px;
      position: relative;
      background: url(../assets/images/index/pic1.png) no-repeat;

      .image {
        width: 640px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.5);
      }
    }

    .pic2 {
      width: 1280px;
      height: 320px;
      background: rgba(44, 62, 92);
      position: relative;
      background: url(../assets/images/index/pic2.png) no-repeat;

      .image {
        width: 1280px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.5);
      }
    }

    .pic3 {
      width: 1280px;
      height: 320px;
      background: rgba(44, 62, 92);
      position: relative;
      background: url(../assets/images/index/pic3.png) no-repeat;

      .image {
        width: 1280px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.5);
      }
    }

    .pic4 {
      width: 640px;
      height: 320px;
      background: rgba(44, 62, 92);
      position: relative;
      background: url(../assets/images/index/pic4.png) no-repeat;

      .image {
        width: 640px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.5);
      }
    }

    .pic5 {
      width: 640px;
      height: 320px;
      background: rgba(44, 62, 92);
      position: relative;
      background: url(../assets/images/index/pic5.png) no-repeat;

      .image {
        width: 640px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.5);
      }
    }

    .pic6 {
      width: 1280px;
      height: 320px;
      background: rgba(44, 62, 92);
      position: relative;
      background: url(../assets/images/index/pic6.png) no-repeat;

      .image {
        width: 1280px;
        height: 320px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(44, 62, 92, 0.5);
      }
    }

    .pic1:hover {
      .image {
        background: rgba(58, 111, 254, 0.9);
      }
    }

    .pic2:hover {
      .image {
        background: rgba(58, 111, 254, 0.9);
      }
    }

    .pic3:hover {
      .image {
        background: rgba(58, 111, 254, 0.9);
      }
    }

    .pic4:hover {
      .image {
        background: rgba(58, 111, 254, 0.9);
      }
    }

    .pic5:hover {
      .image {
        background: rgba(58, 111, 254, 0.9);
      }
    }

    .pic6:hover {
      .image {
        background: rgba(58, 111, 254, 0.9);
      }
    }
  }
}

.MemberServices {
  width: 100%;
  display: flex;
  justify-content: center;

  .MemberContain {
    width: 1920px;
  }

  height: 589px;
  margin: 0;
  padding: 0;
  background-color: rgba(245, 248, 253, 1);

  .topTitle {
    // width: 1920px;
    height: 120px;
    font-size: 36px;
    font-family: YouSheBiaoTiHei;
    color: #2c3e5c;
    line-height: 188px;
    text-align: center;
    // background-color: #fff;
  }

  .contain {
    width: 1120px;
    height: 100%;
    margin-left: 384px;
    margin-top: 45px;
    display: flex;

    .notice {
      width: 265px;
      height: 305px;
      margin-right: 20px;
      background-image: linear-gradient(135deg, #ffffff 0%, #f0f4ff 100%);
      background-repeat: no-repeat;
      background-position: 1% 50%;
      // background: yellow;
      box-shadow: 0px 0px 16px 0px rgba(31, 49, 97, 0.08);
      border-radius: 4px;
      background-size: 200% 200%;
      // opacity: 0;
      transition: all 1s ease-in-out;

      .text {
        margin: 48px 0 0 32px;

        .title {
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          // line-height: 31px;
        }

        .name {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: rgba(85, 85, 85, 0.8);
          margin-top: 32px;
          // line-height: 21px;
          // line-height: 21px;
        }

        .content {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #2c3e5c;
          margin-top: 16px;

          // line-height: 21px;
          .turnButton {
            margin-right: 8px;
          }
        }

        .image {
          margin-left: 86px;
        }
      }

      &:hover {
        // background: url(../assets/images/index/bac.png) no-repeat;
        // background-image: linear-gradient(135deg, #ff00ff 0%, #ffffff 100%);
        background-image: url(../assets/images/index/bac.png);
        background-position: 99% 50%;
        background-size: 100% 100%;
        opacity: 1;

        // background: green;
        .text {
          .title {
            color: #ffffff;
          }

          .name {
            color: rgba(255, 255, 255, 0.6);
          }

          .content {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.LinkPart {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;

  .linkContain {
    width: 1120px;
    height: 100%;
    margin-bottom: 84px;
  }

  .topTitle {
    // width: 1920px;
    height: 120px;
    font-size: 36px;
    font-family: YouSheBiaoTiHei;
    color: #2c3e5c;
    line-height: 188px;
    text-align: center;
  }

  .contain {
    width: 1120px;
    height: 100%;
    margin: 45px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .notice {
      width: 208px;
      height: 64px;
      margin: 0;
      padding: 0;
      box-shadow: 4px 4px 8px 0px rgba(44, 62, 92, 0.08);
      border-radius: 4px;
      // margin-top: 24px;
      // background: no-repeat center/100% url(../assets/images/index/bac2.png);

      .text {
        margin: 48px 0 0 64px;

        .title {
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #2c3e5c;
          // line-height: 31px;
        }

        .name {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #555555;
          margin-top: 32px;
          // line-height: 21px;
          // line-height: 21px;
        }

        .content {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #2c3e5c;
          margin-top: 16px;
          // line-height: 21px;
        }
      }

      &:hover {
        box-shadow: 8px 8px 12px 0px rgba(44, 62, 92, 0.08);
      }
    }

    .notice:nth-child(n + 6) {
      margin-top: 20px;
    }
  }
}

.Comments {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: rgba(245, 248, 253, 1);

  .Contain {
    width: 1120px;
    padding-top: 96px;

    .box {
      // width: 1120px;
      height: 100%;
      // padding: 0 400px;

      // display: flex;
      .inputPart {
        width: 1120px;

        // height: 140px;
        /deep/.el-textarea__inner {
          height: 134px;
          padding: 15px 16px;
          border: none;
          border-radius: 4px;
          min-height: 134px !important;
        }
      }

      .submitButton {
        margin-left: 1007px;
        margin-top: 16px;
        margin-bottom: 21px;
        float: right;

        .el-button--primary {
          width: 80px;
          height: 36px;
          color: #fff;
          background-color: rgba(50, 114, 255, 1);
          border-color: rgba(50, 114, 255, 1);
        }

        .el-button {
          border-radius: 2px;
        }
      }

      .order {
        width: 1120px;
        height: 52px;
        // line-height: 52px;
        // padding: 2px;
        display: flex;
        justify-content: space-between;
        background: #ffffff;

        // margin-top: 10px;
        .left {
          margin: 16px 0 0 16px;

          .number {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #005fe1;
            margin-right: 4px;
          }

          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #191919;
          }
        }

        .right {
          margin: 16px 18px 0 0;

          .text {
            color: rgba(35, 48, 66, 0.6);
            margin: 0 6px 0 0;
          }
        }
      }

      .allComments {
        width: 1120px;
        height: 100%;
        // line-height: 52px;
        // padding: 2px;
        margin-top: 20px;

        // background: #ffffff;
        .blank {
          margin-bottom: 57px;
        }

        .main {
          display: flex;
          justify-content: space-between;

          .left {
            .image {
              width: 24px;
              height: 24px;
              margin-right: 8px;

              img {
                width: 24px;
                height: 24px;
              }
            }

            .name {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #233042;
              margin-top: 4px;
            }
          }

          .right {
            font-size: 12px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: rgba(35, 48, 66, 0.6);
            margin-top: 5px;
          }
        }

        .bottom {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(35, 48, 66, 0.6);
          display: block;
          margin-left: 32px;
          margin-top: 16px;
        }

        .el-divider {
          background: rgba(240, 240, 240, 1);
        }

        .el-divider--horizontal {
          margin: 32px 0 0 0;
        }

        .pageList {
          width: 1120px;
          height: 32px;
          margin-top: 28px;
          margin-bottom: 96px;
          display: flex;
          justify-content: end;

          /deep/.popper__arrow {
            display: none !important;
          }
        }
      }
    }
  }
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2669ff !important;
  border-color: #999999 !important;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #999999 !important;
}

.el-checkbox__input .el-checkbox__inner:hover {
  border-color: #999999 !important;
}
</style>
