<!--
 * @Descripttion:
 * @Author: lina
 * @Date: 2023-01-13 14:14:20
 * @LastEditors: lina
 * @LastEditTime: 2022-05-25 18:49:56
-->
<template>
  <div class="native-bar menuList">
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="rgba(0, 0, 0, 0.1);"
      :text-color="styleChange ? '#333333' : '#ffffff'"
      active-text-color="#ffffff"
      :popper-append-to-body="true"
    >
      <el-menu-item
        index="homeMenu"
        class="firstPage"
        style="
          width: 112px;
          height: 64px;
          line-height: 64px;
          text-align: center;
        "
      >
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <span :style="styleChange ? 'color:#333333' : ''">首页</span>
        </template>
      </el-menu-item>
      <el-submenu index="/zheSays" class="submenuList">
        <template slot="title"
          ><span @click="handleSelect('ZheSays')">"浙"里说</span></template
        >
        <el-menu-item index="7-1">
          <i class="icon1"></i>
          <!-- <img :src="icon1" /> -->
          <span>政策法规</span>
        </el-menu-item>
        <el-menu-item index="7-2">
          <i class="icon2"></i><span>行业资讯</span></el-menu-item
        >
        <el-menu-item index="7-3">
          <i class="icon3"></i><span>专家观点</span></el-menu-item
        >
        <el-menu-item index="7-4" disabled>
          <i class="icon4"></i><span>学习交流</span></el-menu-item
        >
      </el-submenu>
      <el-submenu index="/markSpecification" class="submenuList">
        <template slot="title"
          ><span @click="handleSelect('MarkSpecification')"
            >标准规范</span
          ></template
        >
        <el-menu-item index="6-1">
          <i class="icon5"></i><span>标准文件</span></el-menu-item
        >
        <!-- <el-menu-item index="6-2">
          <i class="icon6"></i><span>标准委员会</span></el-menu-item
        > -->
        <el-menu-item index="6-3">
          <i class="icon7"></i><span>标准计划</span></el-menu-item
        >
        <el-menu-item index="6-4">
          <i class="icon8"></i><span>标准动态</span></el-menu-item
        >
        <el-menu-item index="6-5">
          <i class="icon9"></i><span>标准体系</span></el-menu-item
        >
        <el-menu-item index="6-6" disabled>
          <i class="icon10"></i><span>标准宣贯</span></el-menu-item
        >
      </el-submenu>
      <el-submenu index="/membershipService" class="submenuList">
        <template slot="title"
          ><span @click="handleSelect('MembershipService')"
            >会员服务</span
          ></template
        >
        <el-menu-item index="5-1">
          <i class="icon11"></i><span>管理文件</span></el-menu-item
        >
        <el-menu-item index="5-3">
          <i class="icon12"></i><span>一键入会</span></el-menu-item
        >
        <el-menu-item index="5-5">
          <i class="icon13"></i><span>会员动态</span></el-menu-item
        >
        <el-menu-item index="5-2">
          <i class="icon14"></i><span>会员名录</span></el-menu-item
        >

        <el-menu-item index="5-4" disabled>
          <i class="icon15"></i><span>招聘信息</span></el-menu-item
        >
      </el-submenu>
      <el-submenu index="/associationWork" class="submenuList">
        <template slot="title"
          ><span @click="handleSelect('AssociationWork')"
            >协会工作</span
          ></template
        >
        <el-menu-item index="3-1">
          <i class="icon16"></i><span>党建之窗</span></el-menu-item
        >
        <el-menu-item index="3-2">
          <i class="icon17"></i><span>通知公告</span></el-menu-item
        >
        <el-menu-item index="3-3">
          <i class="icon18"></i><span>协会动态</span></el-menu-item
        >
        <el-menu-item index="3-4">
          <i class="icon19"></i><span>活动相册</span></el-menu-item
        >
        <el-menu-item index="3-5" disabled>
          <i class="icon20"></i><span>成果发布</span></el-menu-item
        >
        <el-menu-item index="3-6">
          <i class="icon29"></i><span>协会期刊</span></el-menu-item
        >
      </el-submenu>
      <el-submenu index="/aboutAssociation" class="submenuList" @open="open">
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <span @click="handleSelect('AboutAssociation')">关于协会</span>
        </template>
        <el-menu-item index="2-1">
          <i class="icon21"></i>
          <span>协会简介</span>
        </el-menu-item>
        <el-menu-item index="2-2">
          <i class="icon22"></i>
          <span>协会章程</span></el-menu-item
        >
        <!-- <el-menu-item index="2-3">
          <i class="icon23"></i>
          <span>领导机构</span></el-menu-item
        > -->
        <el-menu-item index="2-6">
          <i class="icon24"></i>
          <span>组织机构</span></el-menu-item
        >
        <!-- <el-menu-item index="2-5" disabled
          ><i class="icon25"></i><span>专家智库</span></el-menu-item
        > -->

        <el-menu-item index="2-4">
          <i class="icon26"></i>
          <span>协会制度</span></el-menu-item
        >
        <el-menu-item index="2-7">
          <i class="icon27"></i>
          <span>联系我们</span></el-menu-item
        >
        <el-menu-item index="2-8">
          <i class="icon28"></i>
          <span>留言区</span></el-menu-item
        >
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
// import Vue from 'vue'
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)
export default {
  name: 'MenuList',
  props: ['styleChange'],
  data() {
    return {
      icon1: require('../../assets/images/icons/icon1-1.png'),
      icon2: require('../../assets/images/icons/icon1-2.png'),
      icon3: require('../../assets/images/icons/icon1-3.png'),
      icon4: require('../../assets/images/icons/icon1-4.png'),
      icon5: require('../../assets/images/icons/icon2-1.png'),
      icon6: require('../../assets/images/icons/icon2-2.png'),
      icon7: require('../../assets/images/icons/icon2-3.png'),
      icon8: require('../../assets/images/icons/icon2-4.png'),
      icon9: require('../../assets/images/icons/icon2-5.png'),
      icon10: require('../../assets/images/icons/icon2-6.png'),
      icon11: require('../../assets/images/icons/icon3-1.png'),
      icon12: require('../../assets/images/icons/icon3-2.png'),
      icon13: require('../../assets/images/icons/icon3-3.png'),
      icon14: require('../../assets/images/icons/icon3-4.png'),
      icon15: require('../../assets/images/icons/icon3-5.png'),
      icon16: require('../../assets/images/icons/icon4-1.png'),
      icon17: require('../../assets/images/icons/icon4-2.png'),
      icon18: require('../../assets/images/icons/icon4-3.png'),
      icon19: require('../../assets/images/icons/icon4-4.png'),
      icon20: require('../../assets/images/icons/icon4-5.png'),
      icon21: require('../../assets/images/icons/icon5-1.png'),
      icon22: require('../../assets/images/icons/icon5-2.png'),
      icon23: require('../../assets/images/icons/icon5-3.png'),
      icon24: require('../../assets/images/icons/icon5-4.png'),
      icon25: require('../../assets/images/icons/icon5-5.png'),
      icon26: require('../../assets/images/icons/icon5-6.png'),
      icon27: require('../../assets/images/icons/icon5-7.png'),
      activeIndex: '/homeMenu'
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath, '00000')
      this.$emit('changeStyle', false)
      if (key === 'homeMenu') {
        this.$router.push({ path: '/homeMenu' })
      } else if (key === 'ZheSays') {
        this.$router.push({
          name: 'ZheSays',
          query: { index: '1' }
        })
        // this.activeIndex  '/ZheSays'
        // console.log(this.$route.path)
      } else if (key === 'MarkSpecification') {
        this.$router.push({
          name: 'MarkSpecification',
          query: { index: '1' }
        })
      } else if (key === 'MembershipService') {
        this.$router.push({
          name: 'MembershipService',
          query: { index: '1' }
        })
      } else if (key === 'AssociationWork') {
        this.$router.push({
          name: 'AssociationWork',
          query: { index: '1' }
        })
      } else if (key === 'AboutAssociation') {
        this.$router.push({
          name: 'AboutAssociation',
          query: { index: '1' }
        })
      } else if (key.substr(0, 1) === '2') {
        // router.push({path:'/AboutAssociation'})
        this.$router.push({
          name: 'AboutAssociation',
          query: { index: key.substr(2, 1) }
        })
        this.$emit('getKeyValue', key.substr(2, 1))
      } else if (key.substr(0, 1) === '3') {
        this.$router.push({
          name: 'AssociationWork',
          query: { index: key.substr(2, 1) }
        })
        this.$emit('getKeyValue', key.substr(2, 1))
      } else if (key.substr(0, 1) === '4') {
        this.$router.push({
          name: 'HottestRelease',
          query: { index: key.substr(2, 1) }
        })
        this.$emit('getKeyValue', key.substr(2, 1))
      } else if (key.substr(0, 1) === '5') {
        this.$router.push({
          name: 'MembershipService',
          query: { index: key.substr(2, 1) }
        })
        this.$emit('getKeyValue', key.substr(2, 1))
      }
      if (key.substr(0, 1) === '6') {
        this.$router.push({
          name: 'MarkSpecification',
          query: { index: key.substr(2, 1) }
        })
        this.$emit('getKeyValue', key.substr(2, 1))
      }
      if (key.substr(0, 1) === '7') {
        this.$router.push({
          name: 'ZheSays',
          query: { index: key.substr(2, 1) }
        })
        this.$emit('getKeyValue', key.substr(2, 1))
      }
      // console.log(key.substr(2, 1))
      console.log(this.$route.path)
    },
    open(index, path) {
      console.log(index, path, '999')
    },
    goto(url) {
      // console.log(1)
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less">
// .el-sub-menu__title.el-tooltip__trigger.el-tooltip__trigger:hover {
//     color: red;
// }

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  padding: 0 12px !important;
  color: #2c3e5c !important;
  text-align: center;
  line-height: 46px !important;
  font-size: 14px;
  height: 48px !important;
  margin-top: 4px;
}
.el-menu--horizontal .el-menu {
  width: 160px;
  background: rgba(255, 255, 255, 0.88) !important;
}
.el-menu--popup {
  min-width: 120px !important;
  padding: 6px 0px 10px 0px !important;
}
.el-menu--horizontal .el-menu .el-menu-item span {
  margin-left: 12px;
}

.el-menu--horizontal .el-menu .el-menu-item.is-active {
  border-bottom-color: none !important;
  background: linear-gradient(270deg, rgba(50, 114, 255, 0) 0%, #3272ff 100%);
  opacity: 0.3;
}

.el-submenu {
  width: 112px;
  height: 64px;
  line-height: 64px;
  .el-submenu__title {
    height: 65px !important;
    line-height: 65px !important;
    background-color: rgba(255, 255, 255, 0) !important;
    text-align: center;
    i {
      display: none !important;
    }
  }
  .el-submenu__title:hover {
    font-weight: bold !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    ) !important;
    border-bottom: 2px solid #fff !important;
  }
  .el-menu-item {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #2c3e5c !important;
    background-color: rgba(255, 255, 255, 0) !important;
    margin-left: 10px;
    span {
      margin-left: 12px;
    }
  }

  /deep/.el-menu--popup {
    width: 120px;
    height: 100%;
    .el-menu-item {
      margin-left: 32px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #2c3e5c !important;
      background-color: rgba(255, 255, 255, 0.3) !important;
    }

    .el-menu-item.is-active {
      border-bottom-color: none !important;
      background: linear-gradient(
        270deg,
        rgba(50, 114, 255, 0) 0%,
        rgba(50, 114, 255, 0.3) 100%
      );
    }
  }
  .el-menu .el-menu--popup .el-menu--popup-bottom-start {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}
</style>
<style lang="less" scoped>
.el-menu-item {
  background-color: rgba(0, 0, 0, 0) !important;
  i {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .icon1 {
    background: url('../../assets/images/icons/icon1-1@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon2 {
    background: url('../../assets/images/icons/编组 84@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon3 {
    background: url('../../assets/images/icons/编组 91@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon4 {
    background: url('../../assets/images/icons/编组 93@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon5 {
    background: url('../../assets/images/icons/icon2-1@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon6 {
    background: url('../../assets/images/icons/编组 94@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon7 {
    background: url('../../assets/images/icons/编组 96@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon8 {
    background: url('../../assets/images/icons/编组 98@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon9 {
    background: url('../../assets/images/icons/编组 100@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon10 {
    background: url('../../assets/images/icons/编组 102@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon11 {
    background: url('../../assets/images/icons/编组 104@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon12 {
    background: url('../../assets/images/icons/编组 105@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon13 {
    background: url('../../assets/images/icons/编组 107@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon14 {
    background: url('../../assets/images/icons/编组 109@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon15 {
    background: url('../../assets/images/icons/编组 111@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon16 {
    background: url('../../assets/images/icons/编组 113@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon17 {
    background: url('../../assets/images/icons/编组 115@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon18 {
    background: url('../../assets/images/icons/编组 117@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon19 {
    background: url('../../assets/images/icons/编组 119@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon20 {
    background: url('../../assets/images/icons/编组 121@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon21 {
    background: url('../../assets/images/icons/编组 123@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon22 {
    background: url('../../assets/images/icons/编组 125@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon23 {
    background: url('../../assets/images/icons/编组 127@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon24 {
    background: url('../../assets/images/icons/编组 129@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon25 {
    background: url('../../assets/images/icons/编组 131@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon26 {
    background: url('../../assets/images/icons/编组 133@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon27 {
    background: url('../../assets/images/icons/编组 135@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon28 {
    background: url('../../assets/images/icons/编组 4备份@2x.png') no-repeat;
    background-size: 20px 20px;
    margin-left: -12px;
  }
  .icon29 {
    background: url('../../assets/images/icons/编组 5@2x.png') no-repeat;
    background-size: 20px 20px;
  }
}
// .firstPage{
.el-menu-item.firstPage:focus,
.el-menu-item.firstPage:hover {
  color: #fff !important;
  border-bottom: 2px solid #fff !important;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  ) !important;
}
// }
.el-menu-item:focus,
.el-menu-item:hover {
  color: rgba(50, 114, 255, 1) !important;
  border-bottom-color: none !important;
  background: linear-gradient(
    270deg,
    rgba(50, 114, 255, 0) 0%,
    rgba(50, 114, 255, 0.3) 100%
  );
  i {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .icon1 {
    background: url('../../assets/images/icons/ico1-1@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon2 {
    background: url('../../assets/images/icons/编组 84@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon3 {
    background: url('../../assets/images/icons/编组 91@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon4 {
    background: url('../../assets/images/icons/编组 93@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon5 {
    background: url('../../assets/images/icons/ico2-1@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon6 {
    background: url('../../assets/images/icons/编组 94@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon7 {
    background: url('../../assets/images/icons/编组 96@2xx.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon8 {
    background: url('../../assets/images/icons/编组 98@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon9 {
    background: url('../../assets/images/icons/编组 100@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon10 {
    background: url('../../assets/images/icons/编组 102@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon11 {
    background: url('../../assets/images/icons/编组 104@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon12 {
    background: url('../../assets/images/icons/编组 105@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon13 {
    background: url('../../assets/images/icons/编组 107@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon14 {
    background: url('../../assets/images/icons/编组 109@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon15 {
    background: url('../../assets/images/icons/编组 111@2x.png') no-repeat;
    background-size: 20px 20px;
  }
  .icon16 {
    background: url('../../assets/images/icons/编组 113@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon17 {
    background: url('../../assets/images/icons/编组 115@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon18 {
    background: url('../../assets/images/icons/编组 117@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon19 {
    background: url('../../assets/images/icons/编组 119@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon20 {
    background: url('../../assets/images/icons/编组 121@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon21 {
    background: url('../../assets/images/icons/编组 123@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon22 {
    background: url('../../assets/images/icons/编组 125@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon23 {
    background: url('../../assets/images/icons/编组 127@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon24 {
    background: url('../../assets/images/icons/编组 129@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon25 {
    background: url('../../assets/images/icons/编组 131@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon26 {
    background: url('../../assets/images/icons/编组 133@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon27 {
    background: url('../../assets/images/icons/编组 135@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon28 {
    background: url('../../assets/images/icons/编组 4备份@2x(1).png') no-repeat;
    background-size: 20px 20px;
  }
  .icon29 {
    background: url('../../assets/images/icons/编组 5备份@2x.png') no-repeat;
    background-size: 20px 20px;
  }
}
.native-bar {
  width: 672px !important;
  // display: flex;
  // justify-content: center;
  .el-menu-item {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  //设置选中el-menu-item时的状态
  .el-menu-item .is-active {
    border-bottom-color: none !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 100%
    );
    // opacity: 0.8;
  }
  .el-menu {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
