<!--
 * @Descripttion:
 * @Author: lina
 * @Date: 2023-01-13 14:14:20
 * @LastEditors: lina
 * @LastEditTime: 2022-05-25 18:49:56
-->
<template>
  <div class="native-bar">
    <div class="sep-container1">
      <div class="boxContain">
        <div class="contain">
          <span class="left_card">
            <div class="box-card">
              <div slot="header" class="clearfix">
                <span>功能导航</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
              </div>
              <div
                v-for="(item, index) in list1"
                :key="index"
                class="text item"
                @click="
                  $router.push({
                    name: item.pageName,
                    params: { index: '1' },
                  })
                "
              >
                {{ item.name }}
              </div>
            </div>
          </span>
          <span class="left_card">
            <div class="box-card">
              <div slot="header" class="clearfix">
                <span>加入我们</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
              </div>
              <div
              v-for="(item, index) in list2"
                :key="index"
                class="text item"
                @click="
                  $router.push({
                    name: item.pageName,
                    query: { index: item.number },
                  })
                "
              >
                {{ item.name }}
              </div>
            </div>
          </span>
          <span class="left_card">
            <div class="box-card" style="width: 154px; margin-right: 171px">
              <div slot="header" class="clearfix">
                <span>友情链接</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
              </div>
              <div v-for="(item,index) in list3" :key="index" class="text item list"  @click="linkTo(item)">
                {{ item.name }}
              </div>
            </div>
          </span>
          <span class="left_card">
            <div class="box-card" style="width: 100%; margin-right: 120px">
              <div
                class="context"
                :gutter="24"
                style="margin: 0; display: flex"
              >
                <div><img :src="cut3" class="image" /></div>
                <div :span="24" class="text" style="padding: 0">
                  <div class="text_item">
                    <span>(0571) 8788 6763</span>
                  </div>
                  <div class="time">
                    <span>工作日08:30-17:00</span>
                  </div>
                </div>
              </div>
              <div
                class="context"
                :gutter="24"
                style="margin: 0; display: flex"
              >
                <div><img :src="cut4" class="image" /></div>

                <div :span="19" class="text" style="padding: 0">
                  <div class="text_item">
                    <span>业务咨询</span>
                  </div>
                  <div class="time">
                    <span>13567113966@163.com</span>
                  </div>
                </div>
              </div>
              <!-- <div v-for="o in list3" :key="o" class="text item list" >
            {{ o }}
          </div> -->
            </div>
          </span>
          <span class="left_card">
            <div class="box-card">
              <img :src="item" v-for="(item, index) in cutList" :key="index" />
            </div>
            <div class="text" style="margin-top: 16px">官方微信公众号</div>
          </span>
        </div>
        <el-divider></el-divider>
        <div class="bottom_text">copyright@ 2022 by 杭水科技</div>
      </div>
    </div>
  </div>
</template>
<script>
import cut1 from '../../assets/images/bottom/photo.svg'
import cut2 from '../../assets/images/bottom/text.png'
import cut3 from '../../assets/images/bottom/photo2.png'
import cut4 from '../../assets/images/bottom/email.png'

// import cut3 from '../../../assets/images/aboutAssociation/3.png'
// import cut4 from '../../../assets/images/aboutAssociation/4.png'
// import cut5 from './../assets/images/cut5.png'
// import cut6 from './../assets/images/cut6.png'
// import cut7 from './../assets/images/cut7.png'
export default {
  name: 'MenuList',
  data () {
    return {
      activeIndex: '/homeMenu',
      cutList: [cut1],
      cutList2: [cut2],
      cut3,
      cut4,
      list1: [
        { name: '浙"里"说', pageName: 'ZheSays' },
        { name: '标准规范', pageName: 'MarkSpecification' },
        { name: '会员服务', pageName: 'MembershipService' },
        { name: '协会工作', pageName: 'AssociationWork' },
        { name: '关于协会', pageName: 'AboutAssociation' }
      ],
      list2: [{ name: '一键入会', pageName: 'MembershipService', number: '3' },
        { name: '招聘信息', pageName: 'MembershipService', number: '4' }],
      list3: [
        { name: '中国城镇供水排水协会', linkUrl: 'https://www.cuwa.org.cn/' },
        { name: '浙江省住房和城乡建设厅', linkUrl: 'http://jst.zj.gov.cn/' },
        { name: '浙江省水利厅', linkUrl: 'http://slt.zj.gov.cn/' },
        { name: '浙江省环境保护厅', linkUrl: 'http://sthjt.zj.gov.cn/' },
        { name: '浙江省民政厅', linkUrl: 'https://mzt.zj.gov.cn/?uid=4942662&pagenum=0&pageNum=0' }
      ]
    }
  },
  methods: {
    linkTo (item) {
      window.open(item.linkUrl, '_blank')
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    getRouter1 (item) {
      console.log(item, 'pathName')
      this.$router.push({
        name: item.pathName,
        params: { index: '1' }
      })
    },
    getRouter2 (o) {
      if (o === '最热发布') {
        this.$router.push({ path: '/hottestRelease' })
      }
      // console.log(o, 'o222')
    },
    goto (url) {
      console.log(1)
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
.native-bar {
  width: 100%;
  .boxContain {
    width: 1120px;
  }
  .sep-container1 {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 382px;
    color: #ffffff;
    background: #031136;
    // background: #f5f8fd;
    .contain {
      width: 1120px;
      height: 202px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-top: 64px;
    }
    .el-divider--horizontal {
      display: block;
      width: 1120px;
      height: 1px;
      margin: 64px 0 16px 0;
      background: rgba(255, 255, 255, 0.2);
    }
    .bottom_text {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(255, 255, 255, 0.3);
      text-align: center;
    }
    .left_card {
      .text {
        font-size: 14px;
      }

      .item {
        color: rgba(255, 255, 255, 0.6);
        margin-top: 16px;
        // margin-bottom: 18px;
        &:hover{
          color: rgba(255, 255, 255);
        }
      }
      .clearfix {
        margin-bottom: 8px;
      }
      .box-card {
        width: 65px;
        margin-right: 120px;
        .public {
          margin-top: 16px;
        }
        .context {
          // width: 170px;
          // word-wrap: normal;
          // wrap: no-wrap;
          /deep/.el-row {
            margin: 0;
          }
          .image {
            width: 54px;
            height: 54px;
            margin: 0 0 32px 0;
          }

          .text {
            // margin-top: 24px;
            margin-left: 16px;
            margin-bottom: 24px;
            /deep/.el-col {
              padding: 0;
            }
            .text_item {
              // width:137px;
              font-size: 20px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #ffffff;
            }
            .time {
              margin-top: 8px;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
      }
    }
    // background: url(../../assets/images/logo.png) center no-repeat;
    // background-size: cover;
  }

  .el-menu-item {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  //设置选中el-menu-item时的状态
  .el-menu-item .is-active {
    border-bottom-color: none !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    opacity: 0.3;
  }
  .el-menu {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
>
